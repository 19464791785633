import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import styles from "./LocationSelectModal.module.css";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { locations } from "../../../utils/Utils";
import { CastConnectedSharp } from "@mui/icons-material";

const LocationSelectModal = ({
  showModal,
  handleModalClose,
  getSelectedLocationItems = null,
  preselected = null
}) => {
  const { width } = useWindowDimensions();
  const [mainChecked, setMainChecked] = useState(false);
  const [checkBoxes, setCheckBoxes] = useState(locations);

  const handleMainCheckBoxChange = (event) => {
    const { checked } = event.target;
    setMainChecked(checked);
    setCheckBoxes((prevCheckBoxes) =>
      prevCheckBoxes.map((checkbox) => ({
        ...checkbox,
        checked,
        subCheckBoxes: checkbox.subCheckBoxes.map((subCheckbox) => ({
          ...subCheckbox,
          checked
        }))
      }))
    );
  };

  const handleCheckBoxChange = (index) => (event) => {
    const { checked } = event.target;
    setCheckBoxes((prevCheckBoxes) =>
      prevCheckBoxes.map((checkbox, i) =>
        i === index
          ? {
              ...checkbox,
              checked,
              subCheckBoxes: checkbox.subCheckBoxes.map((subCheckbox) => ({
                ...subCheckbox,
                checked
              }))
            }
          : checkbox
      )
    );
  };

  const handleSubCheckBoxChange = (index, subIndex) => (event) => {
    const { checked } = event.target;
    setCheckBoxes((prevCheckBoxes) =>
      prevCheckBoxes.map((checkbox, i) =>
        i === index
          ? {
              ...checkbox,
              subCheckBoxes: checkbox.subCheckBoxes.map((subCheckbox, j) =>
                j === subIndex ? { ...subCheckbox, checked } : subCheckbox
              )
            }
          : checkbox
      )
    );
    updateSubCheckBoxes();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Get all the values of the checked checkboxes
    const selectedValues = checkBoxes.reduce((result, checkbox) => {
      const selectedSubSubCheckBoxes = checkbox.subCheckBoxes
        .filter((subCheckbox) => subCheckbox.checked)
        .map((subCheckbox) => subCheckbox.label);

      result[checkbox.label] = selectedSubSubCheckBoxes;
      return result;
    }, {});

    if (getSelectedLocationItems) {
      let selectedLocationsView = [];
      let seletedLocationInput = [];
      Object.keys(selectedValues).forEach((item) => {
        seletedLocationInput.push(...selectedValues[item]);
        let cr = locations.find((location) => location.label === item);
        if (cr.subCheckBoxes.length == selectedValues[item].length) {
          selectedLocationsView.push(cr.label);
        } else {
          selectedLocationsView.push(...selectedValues[item]);
        }
      });

      getSelectedLocationItems(selectedLocationsView, seletedLocationInput);
    }

    closeModal();
  };

  const updateSubCheckBoxes = () => {
    setCheckBoxes((prevCheckBoxes) =>
      prevCheckBoxes.map((checkbox) => ({
        ...checkbox,
        checked: checkbox.subCheckBoxes.every(
          (subCheckbox) => subCheckbox.checked
        )
      }))
    );
  };

  const handleClearSelected = () => {
    setMainChecked(false);
    setCheckBoxes((prevCheckBoxes) =>
      prevCheckBoxes.map((checkbox) => ({
        ...checkbox,
        checked: false,
        subCheckBoxes: checkbox.subCheckBoxes.map((subCheckbox) => ({
          ...subCheckbox,
          checked: false
        }))
      }))
    );

    handleModalClose();
    getSelectedLocationItems([], []);
  };

  const closeModal = () => {
    handleModalClose();
    // handleClearSelected();
  };

  useEffect(() => {
    if (preselected && preselected.length > 0) {
      setCheckBoxes((prevCheckBoxes) =>
        prevCheckBoxes.map((checkbox) => {
          const areAllSubCheckBoxesChecked = checkbox.subCheckBoxes.every(
            (subCheckbox) => preselected.includes(subCheckbox.label)
          );

          return {
            ...checkbox,
            checked: areAllSubCheckBoxesChecked,
            subCheckBoxes: checkbox.subCheckBoxes.map((subCheckbox) => ({
              ...subCheckbox,
              checked: preselected.includes(subCheckbox.label)
            }))
          };
        })
      );
      setMainChecked(false); // Uncheck the "All prefectures" checkbox
    }
  }, [preselected]);

  useEffect(() => {
    const areAllChecked = checkBoxes.every((item) => item.checked);
    setMainChecked(areAllChecked);
  }, [checkBoxes]);

  return (
    <Modal
      className={`localtionModal ${styles.localtionModal} ${
        width <= 576 ? "mobileModal" : ""
      }`}
      size="lg"
      show={showModal}
      onHide={closeModal}
      centered
    >
      <Modal.Header className={styles.modalHeader} closeButton>
        <p>地域を選択してください</p>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body className={styles.modalbody}>
          <Row className="mb-2">
            <Col sm={12} className="mps-0">
              <label className={styles.mainCheckedMark}>
                <input
                  type="checkbox"
                  checked={mainChecked}
                  onChange={handleMainCheckBoxChange}
                />
                全都道府県
              </label>
            </Col>
          </Row>
          {checkBoxes.map((checkbox, index) => (
            <Row
              key={index}
              className={`${styles.borderTOP} ${
                checkBoxes.length == index + 1 ? styles.borderBottom : ""
              }`}
            >
              <Col
                sm={3}
                className={`${styles.mobileLeft} ${styles.ashBG} py-2`}
              >
                <label>
                  <input
                    type="checkbox"
                    checked={checkbox.checked}
                    onChange={handleCheckBoxChange(index)}
                  />
                  {checkbox.label}
                </label>
              </Col>
              <Col
                sm={9}
                className={`${styles.mobileRight}  ${styles.subsubcheckBox} py-2`}
              >
                {checkbox.subCheckBoxes &&
                  checkbox.subCheckBoxes.map((subCheckbox, subIndex) => (
                    <label key={subIndex}>
                      <input
                        type="checkbox"
                        checked={subCheckbox.checked}
                        onChange={handleSubCheckBoxChange(index, subIndex)}
                      />
                      {subCheckbox.label}
                    </label>
                  ))}
              </Col>
            </Row>
          ))}
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          <Col
            xs={12}
            sm={12}
            className={`text-center ${width <= 576 ? styles.buttons : ""}`}
          >
            <Button
              className={styles.button}
              type="button"
              onClick={handleClearSelected}
            >
              {width > 576 && <>選択した地域をクリア</>}
              {width <= 576 && <>クリア</>}
            </Button>
            <Button className={styles.button} type="submit">
              選択して閉じる
            </Button>
          </Col>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default LocationSelectModal;
