import React, { useState } from "react";
import styles from "./Links.module.css";
import { KeyboardArrowDown } from "@mui/icons-material";
import LocationSelectModal from "./LocationSelectModal";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

const Links = ({ type }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const getSelectedLocationItems = (itemsWithParent, items) => {
    const queryParams = new URLSearchParams();
    queryParams.append("location", items);
    navigate(`/search/?${queryParams.toString()}`);
  };
  return (
    <>
      <div
        className={`${type == "fixed" ? styles.fixedType : ""} ${
          styles.mobileLinkContainer
        }`}
      >
        <a
          style={{ marginLeft: "0px" }}
          className={`${styles.link} ${styles.active}`}
          href="/#filter=find-puppy"
        >
          子犬を探す
        </a>
        <a className={styles.link} href="/#filter=find-adult-dog">
          里親になる
        </a>
        <a className={styles.link} href="/#filter=find-breeder">
          ブリーダーを探す
        </a>
        <a className={styles.link} href="#" onClick={handleModalShow}>
          都道府県から探す
          <KeyboardArrowDown />
        </a>
        <Link
          className={styles.link}
          to="popularBreeder"
          activeClass="active"
          smooth={true}
          duration={300}
        >
          犬種から探す
          <KeyboardArrowDown />
        </Link>
      </div>
      <LocationSelectModal
        showModal={showModal}
        handleModalClose={handleModalClose}
        getSelectedLocationItems={getSelectedLocationItems}
      />
    </>
  );
};

export default Links;
