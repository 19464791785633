import React, { useEffect, useState } from "react";
import styles from "./PopularBreeder.module.css";
import { Col, Row, Button } from "react-bootstrap";
import PopularBreederItem from "./PopularBreederItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useCollection } from "../../hooks/useCollection";
import { useNavigate } from "react-router-dom";

const PopularBreeder = () => {
  const [breeder, setBreeder] = useState([]);
  const navigate = useNavigate();
  const { documents: BreederList } = useCollection(
    "users",
    ["group", "==", "breederpet"],
    "transferMNY",
    "desc",
    3
  );

  useEffect(() => {
    if (BreederList) {
      setBreeder(BreederList);
    }
  }, [BreederList]);

  return (
    <div className="mt-sm-4">
      <h5 className={styles.title}>人気のブリーダー</h5>
      <Row>
        {breeder.map((item, i) => {
          return (
            <Col key={i} sm={12} className="mt-4">
              <PopularBreederItem breeder={item} withList={true} />
            </Col>
          );
        })}
      </Row>
      <Row className="mb-5">
        <Col sm={12} className="text-center mt-4 mt-sm-5">
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/all-breeder");
            }}
            className={styles.loadMoreButton}
          >
            すべて見る <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default PopularBreeder;
