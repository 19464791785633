import React from "react";
import styles from "./CustomBreadcrumb.module.css";
import { Navbar, Container, Row, Col } from "react-bootstrap";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const CustomBreadcrumb = ({ Breadcrumb = null, position = "top" }) => {
  return (
    <>
      {Breadcrumb && (
        <Navbar
          expand="lg"
          className={`${
            position == "top" ? styles.bgBlue : styles.bgTransparent
          }`}
        >
          <Container>
            <Row className="w-100">
              <Col
                sm={12}
                className={` d-flex align-items-center flex-wrap flex-sm-nowrap ${styles.content}`}
              >
                {Breadcrumb.map((item, i) => {
                  if (item.link) {
                    return (
                      <a key={i} href={item.link}>
                        {item.name}
                      </a>
                    );
                  } else {
                    return <p key={i}>{item.name}</p>;
                  }
                })}
              </Col>
            </Row>
          </Container>
        </Navbar>
      )}
    </>
  );
};

export default CustomBreadcrumb;
