import { useState } from "react";
import { db } from "../firebase/config";

//firebase imports
import {
  collection,
  orderBy,
  query,
  limit,
  startAfter,
  where,
  getDocs
} from "firebase/firestore";

export const useCollectionDocsWithIn = () => {
  const [documents, setDocuments] = useState(null);
  //   const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [isLast, setIsLast] = useState(false);

  const getMultipleDocs = async ({
    _collection,
    _query,
    orderByField,
    orderByOption = "desc",
    _limit,
    _startAfter
  }) => {
    setDocuments(null);
    setIsLast(false);
    setIsPending(true);
    let ref = collection(db, _collection);
    let whereIN = {};
    //set the query
    if (Object.values(_query)) {
      Object.values(_query).forEach((qitem) => {
        let queryitem = qitem.split(",");
        if (queryitem[2] === "true" || queryitem[2] === "false") {
          queryitem[2] = queryitem[2] === "true";
        }

        if (queryitem[1] === "in") {
          let chunkArrayItem = chunkArray(queryitem.slice(2), 20);
          let field = queryitem[0];
          let condition = queryitem[1];
          let queryInArray = [];
          chunkArrayItem.forEach((item, i) => {
            queryInArray.push([condition, item]);
          });
          whereIN[field] = queryInArray;
        } else {
          ref = query(ref, where(...queryitem));
        }
      });
    }
    if (orderByField) {
      ref = query(ref, orderBy(orderByField, orderByOption));
    }
    if (_limit) {
      ref = query(ref, limit(_limit));
    }

    if (_startAfter) {
      ref = query(ref, startAfter(_startAfter));
    }

    let results = [];
    if (Object.keys(whereIN).length > 0) {
      let whereInAllArray = [];
      for (const key in whereIN) {
        const uniqueValues = [];
        if (whereIN.hasOwnProperty(key)) {
          let values = whereIN[key];
          if (Array.isArray(values)) {
            await Promise.all(
              values.map(async (item) => {
                let arr = [key, item[0], item[1]];
                let tempRef = query(ref, where(...arr));

                const docSnap = await getDocs(tempRef);
                docSnap.forEach((doc) => {
                  const newDoc = { ...doc.data(), id: doc.id };
                  const isDuplicate = uniqueValues.some(
                    (existingDoc) => existingDoc.id === newDoc.id
                  );

                  if (!isDuplicate) {
                    uniqueValues.push(newDoc);
                  }
                });
              })
            );
          }
        }

        whereInAllArray.push(uniqueValues);
      }
      results = intersection(whereInAllArray);
    } else {
      const docSnap = await getDocs(ref);
      docSnap.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
    }

    setIsPending(false);
    setDocuments(results);
    // setLastDoc(docSnap.docs[docSnap.docs.length - 1]);

    // if (docSnap.empty) {
    //   setIsLast(true);
    // }

    // const unsub = onSnapshot(ref, (snapshot) => {
    //   let results = [];
    //   snapshot.docs.forEach((doc) => {
    //     results.push({ ...doc.data(), id: doc.id });
    //   });
    //   setIsPending(false);
    //   setDocuments(results);
    //   setLastDoc(snapshot.docs[snapshot.docs.length-1])

    //   if(snapshot.empty){
    //     setIsLast(true)
    //   }
    // });

    // return () => unsub();
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  function intersection(arrays) {
    if (arrays.length == 0) {
      return [];
    }

    if (arrays.length == 1) {
      return arrays[0];
    }

    let values = arrays[0];
    for (let i = 1; i < arrays.length; i++) {
      values = values.filter((o) => arrays[i].some(({ id }) => o.id === id));
    }

    return values;
  }
  return { documents, isPending, lastDoc, isLast, getMultipleDocs };
};
