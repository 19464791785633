import { useState } from "react";

export const useAddressFormatter = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const addressFormatter = async (zip3, zip4) => {
    setError(null);
    setIsPending(true);

    return await fetch(
      `https://api.sugartaste.tokyo/api/yubin/zip/${zip3}${zip4}`
    )
      .then(async (data) => {
        setIsPending(false);
        const address = await data.json();
        if (address.city && address.town) {
          return address;
        } else {
          setError("invalid zip");
        }
        return false;
      })
      .catch((err) => {
        setIsPending(false);
        setError(err);
        return false;
      });
  };

  return { isPending, error, addressFormatter };
};
