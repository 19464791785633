import React, { useEffect, useState } from "react";
import { dog_breed, dog_color } from "../../utils/CatDogData";
import { locations } from "../../utils/Utils";

const SearchHistoryItem = ({
  searchHistory,
  setBreedView = null,
  setLocationView = null,
  setColoriew = null
}) => {
  const [text, setText] = useState("ok");
  useEffect(() => {
    let text = "";
    if (searchHistory.breed) {
      let data = genBreedList(searchHistory.breed);
      text += data ? data + " / " : "";
      if (setBreedView && data) {
        setBreedView(data);
      }
    }
    if (searchHistory.location) {
      let data = genLocationList(searchHistory.location);
      text += data ? data + " / " : "";
      if (setLocationView && data) {
        setLocationView(data);
      }
    }
    if (searchHistory.gender) {
      text += searchHistory.gender + "/";
    }
    if (searchHistory.color) {
      let data = genColorList(searchHistory.color);
      text += data ? data + " / " : "";
      if (setColoriew && data) {
        setColoriew(data);
      }
    }
    if (searchHistory.month) {
      text += searchHistory.month + "/";
    }
    if (searchHistory.minPrice) {
      text += searchHistory.minPrice + "/";
    }
    if (searchHistory.maxPrice) {
      text += searchHistory.maxPrice + "/";
    }
    if (searchHistory.birthdate) {
      text += searchHistory.birthdate + "/";
    }
    if (searchHistory.keyword) {
      text += searchHistory.keyword;
    }

    const lastSlashIndex = text.lastIndexOf("/");
    if (lastSlashIndex !== -1) {
      text = text.substring(0, lastSlashIndex);
    }

    text += text;
    text += text;

    setText(text);
  }, []);

  const genBreedList = (items) => {
    if (items && Array.isArray(items)) {
      let viewItem = [];
      if (items.length == dog_breed.length) {
        return "すべて";
      } else {
        let breedItems = items.map((vi) => {
          return dog_breed.find((i) => i.value == vi).label;
        });

        return breedItems.join(" / ");
      }
    }

    return null;
  };

  const genLocationList = (items) => {
    if (items && Array.isArray(items)) {
      let selectedLocationsView = [];

      locations.forEach((checkBox) => {
        let values = checkBox.subCheckBoxes.filter((value) =>
          items.includes(value.label)
        );
        if (values.length == checkBox.subCheckBoxes.length) {
          selectedLocationsView.push(checkBox.label);
        } else {
          values = values.map((i) => i.label);
          selectedLocationsView.push(...values);
        }
      });
      //   let values = locations.filter((value) =>
      //     selectedLocationsView.includes(value.label)
      //   );

      //  if(values.length==locations.length){

      //  }

      return selectedLocationsView.join(" / ");
    }

    return null;
  };
  const genColorList = (items) => {
    if (items && Array.isArray(items)) {
      let viewItem = [];
      if (items.length == dog_color.length) {
        return "すべて";
      } else {
        let breedItems = items.map((vi) => {
          return dog_breed.find((i) => i.value == vi).label;
        });

        return breedItems.join(" / ");
      }
    }

    return null;
  };
  return <div>{text}</div>;
};

export default SearchHistoryItem;
