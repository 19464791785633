import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Footer.module.css";
import { Col, Container, Row } from "react-bootstrap";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogout } from "../../hooks/useLogout";
import LoginModal from "../login/LoginModal";

export default function Footer({ powDiv = true }) {
  const { user } = useAuthContext();
  const { logout } = useLogout();
  const [showLoginModal, setShowLoginModal] = useState(false);

  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  return (
    <div className={`${styles.footer} footer mt-auto`}>
      <div className={styles.footerContainer}>
        <Container>
          {width > 576 && (
            <Row className="justify-content-center">
              <Col xxl={{ span: 10 }} md={{ span: 10 }}>
                <Row className="d-flex justify-content-center align-items-baseline">
                  <Col sm={"auto"} className={styles.col}>
                    <a href="#" className={styles.boldLink}>
                      子犬・成犬・ブリーダーを探す
                    </a>
                    <a href="/#filter=find-puppy" className={styles.link}>
                      子犬を探す
                    </a>
                    <a href="/#filter=find-adult-dog" className={styles.link}>
                      里親になる
                    </a>
                    <a href="/#filter=find-breeder" className={styles.link}>
                      ブリーダーを探す
                    </a>
                  </Col>
                  <Col sm={"auto"} className={`${styles.col} ps-4`}>
                    <a className={styles.boldLink}>
                      ご利用案内
                    </a>
                    <Link to={"/company"} className={styles.link}>
                      運営会社
                    </Link>
                    <Link to="/terms-and-conditions" className={styles.link}>
                      利用規約
                    </Link>
                    <a
                      href="https://petnext-dev.web.app/privacy-policy"
                      className={styles.link}>
                      プライバシーポリシー
                    </a>
                    <Link to="/law" className={styles.link}>
                      特定商取引法に基づく表記
                    </Link>
                  </Col>
                  <Col sm={"auto"} className={`${styles.col} ps-4`}>
                    <a
                      className={`${styles.link} ${
                        width > 576 ? "fw-bold" : ""
                      }`}>
                      お客様サポート
                    </a>
                    {/* <a href="#" className={styles.link}>
                      よくある質問
                    </a> */}
                    <a
                      href={`${process.env.REACT_APP_MCR_URL}/contactus`}
                      className={styles.link}>
                      お問い合わせ
                    </a>
                  </Col>

                  <Col sm={"auto"} className={`${styles.col} ps-4`}>
                    <a
                      className={styles.boldLink}>
                      MCR
                    </a>
                    {!user && (
                      <>
                        <a
                          href={`${process.env.REACT_APP_MCR_URL}signup?group=owner&returnurl=${process.env.REACT_APP_KOINU_URL}&returnsite=${process.env.REACT_APP_KOINU_TITLE}`}
                          className={styles.link}>
                          新規登録
                        </a>
                        <a
                          href="#"
                          className={styles.link}
                          onClick={() => {
                            setShowLoginModal(true);
                          }}>
                          ログイン
                        </a>
                      </>
                    )}

                    {user && (
                      <a
                        href="#"
                        className={styles.link}
                        onClick={() => {
                          setShowLoginModal(false);
                          logout();
                        }}>
                        ログアウト
                      </a>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {width <= 576 && (
            <Row className="justify-content-center">
              <Col xs={11} xxl={{ span: 10 }} md={{ span: 10 }}>
                <Row className="d-flex justify-content-center">
                  <Col xs={7} sm={"auto"} className={styles.col}>
                    <a href="#" className={`${styles.link} fw-bold`}>
                      子犬・成犬・ブリーダーを探す
                    </a>
                    <a href="/#filter=find-puppy" className={styles.link}>
                      子犬を探す
                    </a>
                    <a href="/#filter=find-adult-dog" className={styles.link}>
                      里親になる
                    </a>
                    <a href="/#filter=find-breeder" className={styles.link}>
                      ブリーダーを探す
                    </a>

                    <a
                      className={styles.boldLink}>
                      MCR
                    </a>
                    {!user && (
                      <>
                        <a
                          href={`${process.env.REACT_APP_MCR_URL}signup?group=owner&returnurl=${process.env.REACT_APP_KOINU_URL}&returnsite=${process.env.REACT_APP_KOINU_TITLE}`}
                          className={styles.link}>
                          新規登録
                        </a>
                        <a
                          href="#"
                          className={styles.link}
                          onClick={() => {
                            setShowLoginModal(true);
                          }}>
                          ログイン
                        </a>
                      </>
                    )}

                    {user && (
                      <a
                        href="#"
                        className={styles.link}
                        onClick={() => {
                          setShowLoginModal(false);
                          logout();
                        }}>
                        ログアウト
                      </a>
                    )}
                  </Col>
                  <Col xs={5} sm={"auto"} className={`${styles.col} ps-0`}>
                    <a href="#" className={`${styles.link} fw-bold`}>
                      ご利用案内
                    </a>
                    <Link to={"/company"} className={styles.link}>
                      運営会社
                    </Link>
                    <Link to="/terms-and-conditions" className={styles.link}>
                      利用規約
                    </Link>
                    <a
                      href="https://petnext-dev.web.app/privacy-policy"
                      className={styles.link}>
                      プライバシーポリシー
                    </a>

                    <Link to="/law" className={`${styles.link} fw-bold mt-4`}>
                      特定商取引法に基づく表記
                    </Link>
                    <a href="#" className={styles.link}>
                      お客様サポート
                    </a>
                    {/* <a href="#" className={styles.link}>
                      よくある質問
                    </a> */}
                    <a
                      href={`${process.env.REACT_APP_MCR_URL}/contactus`}
                      className={styles.link}>
                      お問い合わせ
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={12} className="text-center mt-3">
              <p className={styles.copyRight}>
                Copyright © Pet Next All Right Reserved
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <a href="#" className={styles.goUpBar}>
        <img src="/assets/img/goUpBar.png" className="w-100" />
      </a>

      <LoginModal
        show={showLoginModal && !user}
        onHide={() => {
          setShowLoginModal(false);
        }}
        modalShowController={() => {
          setShowLoginModal(false);
        }}
      />
    </div>
  );
}
