import React from "react";
import Slider from "../../components/Home/Slider";
import { Col, Container, Row } from "react-bootstrap";
import TopContent from "../../components/Home/TopContent";
import FilterBar from "../../components/Home/FilterBar";
import AnimalList from "../../components/Home/AnimalList";
import SideBar from "../../components/Home/SideBar/SideBar";
import PopularDogBreedType from "../../components/Home/PopularDogBreedType";
import PopularBreeder from "../../components/Home/PopularBreeder";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styles from "./home.module.css";

function Home() {
  const { width } = useWindowDimensions();

  return (
    <>
      <Container className={`${width <= 576 ? ` ${styles.mobile}` : ""}`}>
        <Row
          style={{ position: "relative", zIndex: -1 }}
          className={`${width <= 576 ? ` ${styles.mobileRow}` : ""}`}
        >
          <Slider />
          <TopContent />
        </Row>
      </Container>
      <FilterBar />

      <div className={styles.grayArea}>
        <Container>
          <Row>
            <Col sm={{ span: 9 }}>
              <AnimalList />
              <PopularDogBreedType />
              <PopularBreeder />
            </Col>
            <Col sm={{ span: 3 }} className={`${width <= 576 ? "px-0" : ""}`}>
              <SideBar />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Home;
