import React, { useEffect, useState } from "react";
import styles from "./PopularDogBreedType.module.css";
import { Row, Button, Col, Placeholder } from "react-bootstrap";
import PopularDogBreedItem from "./PopularDogBreedItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useCollection } from "../../hooks/useCollection";
import { dog_breed } from "../../utils/CatDogData";
import { useNavigate } from "react-router-dom";

const PopularDogBreedType = ({
  moreButton = true,
  limit = 10,
  fulWidth = false
}) => {
  const [dogBreeds, setDogBreeds] = useState(null);
  const navigate = useNavigate();
  const { documents: PetList } = useCollection(
    "microchip",
    ["sellPlatform", "==", true],
    ""
  );

  useEffect(() => {
    // Check if PetList is not null or undefined
    if (PetList) {
      // Update the count in dogBreed based on PetList's "animal_breed" field
      let updatedDogBreed = dog_breed.map((breed) => {
        const count = PetList.filter(
          (pet) => pet.animal_breed === breed.value
        ).length;
        return { ...breed, count };
      });
      updatedDogBreed.sort((a, b) => b.count - a.count);
      if (limit) {
        updatedDogBreed = updatedDogBreed.slice(0, limit);
      }
      setDogBreeds(updatedDogBreed);
    }
  }, [PetList]);
  return (
    <div className="pt-sm-4" id="popularBreeder">
      <h5 className={styles.title}>人気の犬種・犬の種類</h5>
      {!dogBreeds && (
        <Row>
          <Placeholder animation="glow">
            {Array.from({ length: 20 }, (_, i) => (
              <Placeholder
                key={i}
                md={{ span: 6 }}
                lg={{ span: 4 }}
                xl={{ span: 3 }}
                className="rounded-2 p-4"
                style={{ height: "200px" }}
              />
            ))}
          </Placeholder>
        </Row>
      )}
      <div className={styles.row}>
        {dogBreeds && (
          <>
            {dogBreeds.map((item, i) => {
              return (
                <PopularDogBreedItem
                  fulWidth={fulWidth}
                  data={item}
                  pos={i + 1}
                  key={i}
                />
              );
            })}
          </>
        )}
      </div>
      {moreButton && (
        <>
          <Row>
            <Col sm={12} className="text-center mt-3 mt-sm-5">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/all-breed/");
                }}
                className={styles.loadMoreButton}
              >
                すべて見る <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default PopularDogBreedType;
