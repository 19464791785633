import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars, faStar, faVenus } from "@fortawesome/free-solid-svg-icons";

import { dog_breed, dog_color } from "./CatDogData";

export const genderWithIcon = (type) => {
  if (type == 1 || type === "オス") {
    return (
      <>
        男の子 <FontAwesomeIcon icon={faMars} />
      </>
    );
  } else if (type == 2 || type === "メス") {
    return (
      <>
        女の子 <FontAwesomeIcon icon={faVenus} />
      </>
    );
  } else {
    return <></>;
  }
};

export const generateRatingStarts = (count) => {
  let data = [];
  for (let i = 0; i < count; i++) {
    data.push(<FontAwesomeIcon icon={faStar} style={{ color: "#FF9111" }} />);
  }

  return data;
};

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getName = (doc, maxLen = null) => {
  let name =
    doc.type === "1"
      ? `${doc.lastName} ${doc.firstName}`
      : doc.corporation_name;

  if (maxLen && name.length > maxLen) {
    name = doc.type === "1" ? doc.lastName : doc.corporation_name;
    if (name.length > maxLen) {
      name = `${name.slice(0, maxLen - 1)}...`;
    }
  }

  return name;
};

export const petCardDataGenerator = (pet) => {
  let petData = {};
  petData.animalID = pet.animalID;
  if (pet.images && pet.images.length > 0) {
    petData.image = pet.images[0];
  } else {
    petData.image = "/assets/img/animalPreview.png";
  }

  if (pet.animal_gender == "オス") {
    petData.gender = 1;
  } else {
    petData.gender = 2;
  }

  petData.animal_type =
    pet.animal_breed !== "99"
      ? _.find(dog_breed, ["value", pet.animal_breed])?.label
      : pet.animal_breed_other;
  petData.birthday = pet.animal_birthDay;


  petData.color =
    pet.animal_color !== "99"
      ? _.find(dog_color, ["value", pet.animal_color])?.label
      : pet.animal_color_other;

  petData.zip3 = pet.sellPlatform_zip3;
  petData.zip4 = pet.sellPlatform_zip4;
  petData.price = parseInt(pet.sellPlatformPrice).toLocaleString("en-US") || "";
  petData.prefecture = pet.sellPlatform_prefecture;
  petData.sellPlatformAt = pet.sellPlatformAt;

  if (!pet.sellPlatformAt) {
    return null;
  }

  return petData;
};

export const locations = [
  {
    label: "北海道・東北",
    checked: false,
    subCheckBoxes: [
      { label: "北海道", checked: false },
      { label: "青森県", checked: false },
      { label: "岩手県", checked: false },
      { label: "秋田県", checked: false },
      { label: "宮城県", checked: false },
      { label: "山形県", checked: false },
      { label: "福島県", checked: false }
    ]
  },
  {
    label: "関東",
    checked: false,
    subCheckBoxes: [
      { label: "東京都", checked: false },
      { label: "神奈川県", checked: false },
      { label: "千葉県", checked: false },
      { label: "埼玉県", checked: false },
      { label: "茨城県", checked: false },
      { label: "栃木県", checked: false },
      { label: "群馬県", checked: false }
    ]
  },
  {
    label: "甲信越・北陸",
    checked: false,
    subCheckBoxes: [
      { label: "山梨県", checked: false },
      { label: "長野県", checked: false },
      { label: "新潟県", checked: false },
      { label: "富山県", checked: false },
      { label: "石川県", checked: false },
      { label: "福井県", checked: false }
    ]
  },
  {
    label: "中部・東海",
    checked: false,
    subCheckBoxes: [
      { label: "静岡県", checked: false },
      { label: "愛知県", checked: false },
      { label: "岐阜県", checked: false },
      { label: "三重県", checked: false }
    ]
  },
  {
    label: "関西・近畿",
    checked: false,
    subCheckBoxes: [
      { label: "大阪府", checked: false },
      { label: "兵庫県", checked: false },
      { label: "京都府", checked: false },
      { label: "滋賀県", checked: false },
      { label: "奈良県", checked: false },
      { label: "和歌山県", checked: false }
    ]
  },
  {
    label: "中国",
    checked: false,
    subCheckBoxes: [
      { label: "岡山県", checked: false },
      { label: "広島県", checked: false },
      { label: "鳥取県", checked: false },
      { label: "島根県", checked: false },
      { label: "山口県", checked: false }
    ]
  },
  {
    label: "四国",
    checked: false,
    subCheckBoxes: [
      { label: "愛媛県", checked: false },
      { label: "香川県", checked: false },
      { label: "高知県", checked: false },
      { label: "徳島県", checked: false }
    ]
  },
  {
    label: "九州・沖縄",
    checked: false,
    subCheckBoxes: [
      { label: "福岡県", checked: false },
      { label: "佐賀県", checked: false },
      { label: "長崎県", checked: false },
      { label: "熊本県", checked: false },
      { label: "大分県", checked: false },
      { label: "宮崎県", checked: false },
      { label: "鹿児島県", checked: false },
      { label: "沖縄県", checked: false }
    ]
  }
  // Add more sub-checkboxes as needed
];

export const setCache = (key, data, time = "5m") => {
  const currentTime = new Date().getTime();
  const expirationTime = calculateExpirationTime(time);

  const cacheData = {
    data,
    expiresAt: currentTime + expirationTime
  };

  localStorage.setItem(key, JSON.stringify(cacheData));
};

export const getCache = (key) => {
  const cachedItem = localStorage.getItem(key);

  if (cachedItem) {
    const { data, expiresAt } = JSON.parse(cachedItem);

    if (!expiresAt || new Date().getTime() < expiresAt) {
      return data;
    }

    // Remove the item from localStorage if it has expired
    localStorage.removeItem(key);
  }

  return null;
};

const calculateExpirationTime = (time) => {
  const millisecondsInSecond = 1000;
  const millisecondsInMinute = 60 * millisecondsInSecond;
  const millisecondsInHour = 60 * millisecondsInMinute;

  const [amount, unit] = time.match(/\d+|\D+/g);

  switch (unit) {
    case "s":
      return amount * millisecondsInSecond;
    case "m":
      return amount * millisecondsInMinute;
    case "h":
      return amount * millisecondsInHour;
    default:
      return amount * millisecondsInMinute; // Default to minutes
  }
};
