import React, { useEffect, useState } from "react";
import { Modal, Button, Col, Row, Form } from "react-bootstrap";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styles from "./ArrayModal.module.css";
import SearchHistoryItem from "../../components/Universal/SearchHistoryItem";

const SearchHistoryModal = ({
  showModal,
  handleModalClose,
  updateFunction,
  array_item = []
}) => {
  const { width } = useWindowDimensions();
  const [breedView, setBreedView] = useState("");
  const [colorView, setColoriew] = useState("");
  const [locationView, setLocationView] = useState("");

  useEffect(() => {
    // console.log(locationView, breedView, colorView);
  }, [locationView, breedView, colorView]);

  return (
    <Modal
      className={styles.searchHistory}
      size="lg"
      show={showModal}
      onHide={handleModalClose}
    >
      <Modal.Header className={styles.searchmodalHeader} closeButton>
        <p>すべての検索履歴</p>
      </Modal.Header>
      <Modal.Body>
        {array_item.map((item, i) => {
          return (
            <div
              key={i}
              className={styles.searchHistoryItem}
              onClick={(e) => {
                updateFunction(item, breedView, locationView, colorView);
              }}
            >
              <SearchHistoryItem
                setBreedView={setBreedView}
                setLocationView={setLocationView}
                setColoriew={setColoriew}
                searchHistory={item}
              />
            </div>
          );
        })}
      </Modal.Body>
      {/* <Modal.Footer>
        <Row className="w-100">
          <Col xs={12} sm={12} className={`text-center`}>
            <Button type="button">クリア</Button>
          </Col>
        </Row>
      </Modal.Footer> */}
    </Modal>
  );
};

export default SearchHistoryModal;
