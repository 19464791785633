import React, { useState } from "react";

import _ from "lodash";
import moment from "moment";

import styles from "./PuppyShortCard.module.css";
import { genderWithIcon } from "../../utils/Utils";
import { dog_breed, dog_color } from "../../utils/CatDogData";
import useWindowDimensions from "../../../src/hooks/useWindowDimensions";
import { Link } from "react-router-dom";

const PuppyShortCard = ({ animalData, showType = true }) => {
  const [image, setImage] = useState("/assets/img/sellPuppy.png");
  const [gender, setGebder] = useState(2); //1:男の子 2:女の子
  const [type, setType] = useState("トイ・プードル");
  const [date, setDate] = useState("2023/5/10");
  const [price, setPrice] = useState(300000);
  const { width } = useWindowDimensions();

  return (
    <Link
      to={`/pet/${animalData.animalID}`}
      className="text-decoration-none text-reset"
    >
      <div className={styles.PuppyShortCard}>
        <div className={styles.imgContainer}>
          <div className="ratio ratio-1x1">
            <img
              alt=""
              src={animalData.images[0] || "/assets/img/sellPuppy.png"}
              className="w-100"
            />
          </div>
        </div>

        {width > 576 && (
          <>
            <div className={styles.detailsContainer}>
              <div className={styles.gender}>
                {genderWithIcon(animalData.mcData.animal_gender)}
              </div>
              {showType && (
                <h6 className={styles.type}>
                  {animalData.mcData.animal_breed !== "999"
                    ? _.find(dog_breed, [
                        "value",
                        animalData.mcData.animal_breed
                      ])?.label
                    : animalData.mcData.animal_breed_other}
                </h6>
              )}
              <p className={styles.date}>
                {moment(animalData.mcData.animal_birthDay).format(
                  "YYYY年MM月DD日"
                )}
                生まれ
              </p>
              <p className={styles.price}>
                {parseInt(animalData.sellPlatformPrice).toLocaleString()}
                円（税込）
              </p>
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export default PuppyShortCard;
