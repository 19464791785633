import React, { useEffect, useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { useGetDoc } from "./useGetDocs";
import { useSetDoc } from "./useSetDoc";
import { arrayRemove, arrayUnion } from "firebase/firestore";

export const useFavourite = (petID) => {
  
  const [isPending, setIsPending] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [totalFav, setTotalFav] = useState(0);
  const { user } = useAuthContext();
  const {
    getSingleDocWithQuery,
    data,
    isPending: isPending2,
    error
  } = useGetDoc();
  const {
    firebaseSetDoc,
    firebaseSetDocQuery,
    isPending: isPending3,
    error: error4
  } = useSetDoc();

  const checkFav = async () => {
    setIsPending(true);
    if (!user) {
      setIsReady(false);
      return;
    }
    const mc = await getSingleDocWithQuery("microchip", [["animalID", "==", petID]]);
    let fav_ = false;
    if (mc && mc[0].favourite) {
      setTotalFav(mc[0].favourite.length);
      mc[0].favourite.map((fav) => {
        if (fav == user.uid) {
          fav_ = true;
        }
      });
    }
    setIsFavourite(fav_);
    setIsReady(true);
    setIsPending(false);
  };

  useEffect(() => {
    checkFav();
    totalFavCounter();
  }, [user]);

  const addToFavourite = async () => {
    // console.log("addToFavourite", petID)
    if (!isReady) {
      return;
    }
    setIsPending(true);
    await firebaseSetDoc(
      `microchip`,
      {
        favourite: arrayUnion(user.uid)
      },
      petID,
      true
    );

    await firebaseSetDocQuery(
      `users/${user.uid}/microchip/`,
      {
        mcData: {
          favourite: arrayUnion(user.uid)
        }
      },
      [["mccode", "==", petID]],
      true
    );

    await firebaseSetDoc(
      `users`,
      {
        favourite: arrayUnion(petID)
      },
      user.uid,
      true
    );

    setIsPending(false);
    checkFav();
    totalFavCounter();
  };

  const removeFromFavourite = async () => {
    if (!isReady) {
      return;
    }
    setIsPending(true);
    await firebaseSetDoc(
      `microchip`,
      {
        favourite: arrayRemove(user.uid)
      },
      petID,
      true
    );
    await firebaseSetDocQuery(
      `users/${user.uid}/microchip/`,
      {
        mcData: {
          favourite: arrayRemove(user.uid)
        }
      },
      [["mccode", "==", petID]],
      true
    );
    await firebaseSetDoc(
      `users`,
      {
        favourite: arrayRemove(petID)
      },
      user.uid,
      true
    );
    setIsPending(false);
    checkFav();
    totalFavCounter();
  };

  const totalFavCounter = async () => {
    setIsPending(true);
    const mc = await getSingleDocWithQuery("microchip", [["animalID", "==", petID]]);
    let fav_ = false;
    if (mc && mc[0].favourite) {
      setTotalFav(mc[0].favourite.length);
      setIsPending(false);
    }
  };

  return {
    isReady,
    isFavourite,
    isPending,
    addToFavourite,
    removeFromFavourite,
    totalFav
  };
};

export default useFavourite;
