import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Links from "./Links";
import styles from "./SecondHeader.module.css";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const SecondHeader = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <div className={styles.SecondHeader}>
        {width <= 576 && <Links type={"normal"} />}
        {width > 576 && (
          <>
            <Container>
              <Row>
                <Col sm={12}>
                  <Links type={"normal"} />
                </Col>
              </Row>
            </Container>
          </>
        )}
      </div>

      {/* <div className={styles.apperHeader}></div> */}
    </>
  );
};

export default SecondHeader;
