import React, { useEffect, useState } from "react";
import Style from "./Static.module.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CustomBreadcrumb from "../../components/Universal/CustomBreadcrumb";
import { Col, Container, Row } from "react-bootstrap";

const Company = () => {
  let [breadcrumb, setBreadcrumb] = useState(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    let breadcrumbItem = [];
    breadcrumbItem.push({ name: "LOOKFOR ", link: "/" });
    breadcrumbItem.push({ name: "運営会社 " });
    setBreadcrumb(breadcrumbItem);
  }, []);
  return (
    <>
      {width > 576 && (
        <>
          <CustomBreadcrumb Breadcrumb={breadcrumb} />
        </>
      )}
      <div className={Style.company}>
        <Container className="mb-sm-5">
          <Row>
            <Col sm={12}>
              <h5 className={Style.title}>運営会社</h5>
            </Col>
          </Row>

          <div className="my-sm-4 w-100 px-2">
            <Row>
              <div className={Style.key}>法人名</div>
              <div className={Style.value}>株式会社detect</div>
            </Row>
            <Row>
              <div className={Style.key}>代表取締役</div>
              <div className={Style.value}>本舘 拓武</div>
            </Row>
            <Row>
              <div className={Style.key}>所在地</div>
              <div className={Style.value}>
                150-0041<br></br>
                渋谷区神南1-23-14　<br className="d-block d-sm-none"></br>
                リージャス渋谷公園通り310
              </div>
            </Row>
            <Row>
              <div className={Style.key}>電話番号</div>
              <div className={Style.value}>03-6892-7285</div>
            </Row>
            <Row>
              <div className={Style.key}>URL</div>
              <div className={Style.value}>https://detect.co.jp</div>
            </Row>
            <Row>
              <div className={Style.key}>事業内容</div>
              <div className={Style.value}>
                - OEM事業部<br></br>- WEB開発事業部<br></br>- SNS事業部<br></br>
                - クリエイティブ事業部
              </div>
            </Row>
            <Row>
              <div className={`${Style.key} border-bottom-0`}>設立</div>
              <div className={`${Style.value} border-bottom-0`}>2019年6月</div>
            </Row>
          </div>
        </Container>

        <div className="w-100">
          <CustomBreadcrumb position={"bottom"} Breadcrumb={breadcrumb} />
        </div>
      </div>
    </>
  );
};

export default Company;
