import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./FixedHeader.module.css";
import Links from "./Links";

const FixedHeader = () => {
  return (
    <>
      <div className={styles.FixedHeader}>
        <Container>
          <Row>
            <Col sm={{ span: 1 }}>
              <a href="/" className={styles.title}>
                LOOKFOR
              </a>
            </Col>
            <Col
              sm={{ span: 10 }}
              className="text-center d-flex justify-content-center align-items-center"
            >
              <Links type={"fixed"} />
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className={styles.apperHeader}></div> */}
    </>
  );
};

export default FixedHeader;
