import React, { useEffect, useState } from "react";
import Style from "./Static.module.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CustomBreadcrumb from "../../components/Universal/CustomBreadcrumb";
import { Col, Container, Row } from "react-bootstrap";

const CommercialTransactionsLaw = () => {
  let [breadcrumb, setBreadcrumb] = useState(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    let breadcrumbItem = [];
    breadcrumbItem.push({ name: "LOOKFOR ", link: "/" });
    breadcrumbItem.push({ name: "特定商取引法に基づく表記 " });
    setBreadcrumb(breadcrumbItem);
  }, []);
  return (
    <>
      {width > 576 && (
        <>
          <CustomBreadcrumb Breadcrumb={breadcrumb} />
        </>
      )}
      <div className={Style.company}>
        <Container className="mb-sm-5">
          <Row>
            <Col sm={12}>
              <h5 className={Style.title}>特定商取引法に基づく表記</h5>
            </Col>
          </Row>

          <div className="my-sm-4 w-100 px-2">
            <Row>
              <div className={Style.key}>販売業者</div>
              <div className={Style.value}>株式会社detect</div>
            </Row>
            <Row>
              <div className={Style.key}>責任者</div>
              <div className={Style.value}>本舘 拓武</div>
            </Row>
            <Row>
              <div className={Style.key}>住所</div>
              <div className={Style.value}>
                150-0041<br></br>
                渋谷区神南1-23-14　<br className="d-block d-sm-none"></br>
                リージャス渋谷公園通り310
              </div>
            </Row>
            <Row>
              <div className={Style.key}>電話番号</div>
              <div className={Style.value}>
                03-6892-7285 <br /><br />
                受付時間 13:00-17:00（土日祝を除く）<br /><br />
                ※受付時間外の場合は、メールにてお問い合わせください。
              </div>
            </Row>
            <Row>
              <div className={Style.key}>メールアドレス</div>
              <div className={Style.value}>
                <a href="mailto:info@detect.co.jp">info@detect.co.jp</a>
              </div>
            </Row>
            <Row>
              <div className={Style.key}>販売価格</div>
              <div className={Style.value}>マイクロチップ登録料にシステム利用料およびマイクロチップ保険料を含めた価格</div>
            </Row>


            <Row>
              <div className={Style.key}>商品以外の必要料金</div>
              <div className={Style.value}>特にありません。</div>
            </Row>

            <Row>
              <div className={Style.key}>支払方法</div>
              <div className={Style.value}>クレジットカード決済のみ</div>
            </Row>

            <Row>
              <div className={Style.key}>支払時期</div>
              <div className={Style.value}>クレジットカード決済：商品注文時にお支払いが確定します。</div>
            </Row>

            <Row>
              <div className={Style.key}>商品の引渡時期</div>
              <div className={Style.value}>ご注文日から</div>
            </Row>
            
            <Row>
              <div className={`${Style.key} border-bottom-0`}>キャンセル規定</div>
              <div className={`${Style.value} border-bottom-0`}>システム利用後、翌日にはマイクロチップ登録が行われますので、利用日の１７：００までにご連絡頂いた場合のみ。キャンセルが可能です。</div>
            </Row>
          </div>
        </Container>

        <div className="w-100">
          <CustomBreadcrumb position={"bottom"} Breadcrumb={breadcrumb} />
        </div>
      </div>
    </>
  );
};

export default CommercialTransactionsLaw;
