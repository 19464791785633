import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import PetsIcon from "@mui/icons-material/Pets";
import PersonIcon from "@mui/icons-material/Person";
import { useCollectionDocs } from "../../../hooks/useCollectionDocs";
import { useCollection } from "../../../hooks/useCollection";
import { useAggregation } from "../../../hooks/useAggregation";

const TotalCountComponent = () => {
  const [totalPuppies, setTotalPuppies] = useState(0);
  const [totalBreeders, setTotalBreeders] = useState(0);
  const { width } = useWindowDimensions();
  const { countWithQuery } = useAggregation();

  useEffect(() => {
    const loadData = async () => {
      const BreederCount = await countWithQuery(`users`, [
        ["group", "==", "breederpet"]
      ]);
      const petCount = await countWithQuery(`microchip`, [
        ["sellPlatform", "==", true]
      ]);

      if (BreederCount && petCount) {
        setTotalBreeders(BreederCount.toLocaleString());
        setTotalPuppies(petCount.toLocaleString());
      }
    };

    loadData();
  }, []);

  return (
    <>
      {width > 576 && (
        <div className={styles.countText}>
          <div>
            <PetsIcon className={styles.matIcon} /> ワンちゃん掲載数{" "}
            <span className="fw-bold">{totalPuppies}</span> 頭
          </div>
          <div>
            <PersonIcon className={styles.matIcon} /> 登録ブリーダー数{" "}
            <span className="fw-bold">{totalBreeders}</span>人
          </div>
        </div>
      )}
      {width <= 576 && (
        <div className={styles.countText}>
          ワンちゃん掲載数<span className="fw-bold">{totalPuppies}</span>頭
          <br></br>
          ブリーダー数<span className="fw-bold">{totalBreeders}</span>人
        </div>
      )}
    </>
  );
};

export default TotalCountComponent;
