import { db } from "../firebase/config";

//firebase imports
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useGetDocSnap = (c, id) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  useEffect(() => {
    let ref = doc(db, c, id);
    const unsub = onSnapshot(ref, (snapshot) => {
      if (snapshot.exists()) {
        setData({ ...snapshot.data() });
        setIsPending(false);
      } else {
        // console.log("No such document!");
      }
    });

    return () => unsub();
  }, [c, id]);
  return { data, isPending };
};
