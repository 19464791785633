import React from "react";

import { Col, Placeholder, Row, Ratio } from "react-bootstrap";

import AddressFormatter from "../AddressFormatter";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getName } from "../../utils/Utils";

function BreederSortDetailsInquery({ isPending, breeder = false, petDatils }) {
  const { width } = useWindowDimensions();

  return (
    <>
      {isPending && (
        <Row className="placeholder-glow">
          <Col lg={3} xs={5}>
            <Ratio aspectRatio={100}>
              <Placeholder />
            </Ratio>
          </Col>

          <Col lg={9} xs={7} className="position-relative d-flex align-items-center">
            <div
              className={`${width < 576 && `fs-7 pe-3`} ${
                width >= 576 &&
                `position-absolute top-50 start-50 translate-middle`
              } w-100 px-lg-5`}>
              <Row className="border-1 border-dark border-bottom py-lg-3">
                <Col sm={{ span: 4, offset: 1 }}>
                  <Placeholder xs={3} className="rounded-1" />
                </Col>
                <Col sm={6}>
                  <Placeholder xs={7} className="rounded-1" />
                </Col>
              </Row>
              <Row className="border-1 border-dark border-bottom py-lg-3">
                <Col sm={{ span: 4, offset: 1 }}>
                  <Placeholder xs={5} className="rounded-1" />
                </Col>
                <Col sm={6}>
                  <Placeholder xs={8} className="rounded-1" />
                </Col>
              </Row>
              <Row className="border-1 border-dark border-bottom py-lg-3">
                <Col sm={{ span: 4, offset: 1 }}>
                  <Placeholder xs={4} className="rounded-1" />
                </Col>
                <Col sm={6}>
                  <Placeholder xs={4} className="rounded-1" />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}

      {!isPending && breeder && (
        <Row>
          <Col lg={3} xs={5}>
            <Ratio aspectRatio={100}>
              <img
                src={breeder?.img || "/assets/img/PopularBreederDog.png"}
                className="lozad img-fluid object-fit-cover"
                alt="pet"
              />
            </Ratio>
          </Col>

          <Col lg={9} xs={7} className="position-relative d-flex align-items-center">
            <div
              className={`${width < 576 && `fs-7 pe-3`} ${
                width >= 576 &&
                `position-absolute top-50 start-50 translate-middle`
              } w-100 px-lg-5`}>
              <Row className={` border-1 border-dark border-bottom py-lg-3`}>
                <Col sm={{ span: 4, offset: 1 }}>
                  <b>名前</b>
                </Col>
                <Col sm={6}>
                  {getName(breeder)} <span>ブリーダー</span>
                </Col>
              </Row>
              <Row className="border-1 border-dark border-bottom py-lg-3">
                <Col sm={{ span: 4, offset: 1 }}>
                  <b>見学地域</b>
                </Col>
                <Col sm={6}>
                  <AddressFormatter
                    zip3={petDatils.sellPlatform_zip3}
                    zip4={petDatils.sellPlatform_zip4}
                    withArrow={false}
                  />
                </Col>
              </Row>
              {breeder.animal_handler_business_type1_slot1 && <Row className="border-1 border-dark border-bottom py-lg-3">
                <Col sm={{ span: 4, offset: 1 }}>
                  <b>動物取扱業登録番号</b>
                </Col>
                <Col sm={6}>{breeder.animal_handler_business_type1_slot1}</Col>
              </Row>}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}

export default BreederSortDetailsInquery;
