import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CustomBreadcrumb from "../../components/Universal/CustomBreadcrumb";
import PopularDogBreedType from "../../components/Home/PopularDogBreedType";
import { Container, Row, Placeholder, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { dog_breed } from "../../utils/CatDogData";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import { petCardDataGenerator } from "../../utils/Utils";
import AnimalItem from "../../components/Home/AnimalItem";
import SideBar from "../../components/Home/SideBar/SideBar";
import styles from "./../home/home.module.css";
const BreedAnimal = () => {
  const params = useParams();
  let [breadcrumb, setBreadcrumb] = useState(null);
  const { width } = useWindowDimensions();
  const animalItemRef = useRef(null);
  const [animalList, setAnimalList] = useState(null);
  const {
    documents,
    isPending: isPendingList,
    getMultipleDocs
  } = useCollectionDocs();
  useEffect(() => {
    let breadcrumbItem = [];
    breadcrumbItem.push({ name: "サイト名", link: "/" });
    breadcrumbItem.push({
      name: dog_breed.find((item) => item.value === params.id).label || ""
    });
    setBreadcrumb(breadcrumbItem);
  }, []);

  useEffect(() => {
    let pets = [];
    if (documents) {
      documents.forEach((element) => {
        pets.push(petCardDataGenerator(element));
      });
      setAnimalList(pets);
      setTimeout(() => {
        makeEqualHeightofCards();
      }, 500);
    }
  }, [documents]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    getMultipleDocs({
      _collection: `microchip`,
      _query: {
        1: `sellPlatform,==,true`,
        2: `animal_breed,==,${params.id}`
      },
      orderByField: "sellPlatformAt"
    });
  };

  useEffect(() => {
    if (animalItemRef) {
      const container = animalItemRef.current.parentNode;
      const cards = container.getElementsByClassName("AnimalCard");
      for (let i = 0; i < cards.length; i++) {
        cards[i].style.height = `auto`;
      }
      makeEqualHeightofCards();
    }
  }, [width]);

  const makeEqualHeightofCards = () => {
    const container = animalItemRef.current.parentNode;
    const cards = container.getElementsByClassName("AnimalCard");
    let maxHeight = 0;
    for (let i = 0; i < cards.length; i++) {
      const cardHeight = cards[i].clientHeight;
      if (cardHeight > maxHeight) {
        maxHeight = cardHeight;
      }
    }
    for (let i = 0; i < cards.length; i++) {
      cards[i].style.height = `${maxHeight}px`;
    }
  };
  return (
    <>
      {width > 576 && (
        <>
          <CustomBreadcrumb Breadcrumb={breadcrumb} />
        </>
      )}
      <div className={`mb-3 mb-sm-5 ${styles.grayArea}`}>
        <Container>
          <Row ref={animalItemRef}>
            <Col sm={{ span: 9 }}>
              <Row>
                {!animalList && (
                  <>
                    <Placeholder animation="glow">
                      {Array.from({ length: 8 }, (_, i) => (
                        <Placeholder
                          key={i}
                          md={{ span: 6 }}
                          lg={{ span: 4 }}
                          xl={{ span: 3 }}
                          className="rounded-2 p-4"
                          style={{ height: "400px" }}
                        />
                      ))}
                    </Placeholder>
                  </>
                )}

                {animalList && (
                  <>
                    {animalList.map((item, index) => {
                      return (
                        <Col
                          key={index}
                          md={{ span: 6 }}
                          lg={{ span: 4 }}
                          xl={{ span: 3 }}
                          className="mt-2"
                        >
                          <AnimalItem
                            animalItemRef={animalItemRef}
                            data={item}
                          />
                        </Col>
                      );
                    })}
                  </>
                )}
              </Row>
            </Col>

            <Col sm={{ span: 3 }} className={`${width <= 576 ? "px-0" : ""}`}>
              <SideBar />
            </Col>
          </Row>
        </Container>
        {width <= 576 && (
          <>
            <CustomBreadcrumb position={"bottom"} Breadcrumb={breadcrumb} />
          </>
        )}
      </div>
    </>
  );
};

export default BreedAnimal;
