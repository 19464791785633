import React, { useEffect, useState } from "react";
import { Modal, Button, Col, Row, Form } from "react-bootstrap";
import styles from "./ArrayModal.module.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const ArrayModal = ({
  showArrayModal,
  handleModalClose,
  updateFunction,
  array_item = [],
  preselected = []
}) => {
  const [arrayItems, setArrayItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [mainChecked, setMainChecked] = useState(false);
  const { width } = useWindowDimensions();

  const handleMainCheckBoxChange = (event) => {
    const { checked } = event.target;
    setMainChecked(checked);
    let items = [];
    arrayItems.map((breed) => {
      breed.checked = checked;
      items.push(breed);
    });
    setArrayItems(items);
  };
  const handleCheckBoxChange = (event, item) => {
    const { checked } = event.target;
    let items = [];
    arrayItems.map((breed) => {
      if (breed.value == item.value) {
        breed.checked = checked;
      }
      items.push(breed);
    });
    setArrayItems(items);
  };
  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    let items = arrayItems.filter((item) => item.checked === true);
    let viewItem = [];
    if (items.length == array_item.length) {
      viewItem = [{ label: "すべて" }];
    } else {
      viewItem = [...items];
    }
    updateFunction(viewItem, items);
    handleModalClose();
  };

  const clearAll = () => {
    setCheckedItems({});
    setMainChecked(false);
    let items = [];
    arrayItems.map((breed) => {
      breed.checked = false;
      items.push(breed);
    });
    setArrayItems(items);
    handleSubmit();
  };
  useEffect(() => {
    let items = [];
    array_item.map((breed, i) => {
      let selected = preselected.some((value) => value === breed.value);
      breed.checked = selected;
      items.push(breed);
    });
    setArrayItems(items);
  }, [preselected, array_item]);

  return (
    <Modal
      size="lg"
      className={`${styles.arrayModal} ${width <= 576 ? "mobileModal" : ""}`}
      show={showArrayModal}
      onHide={handleModalClose}
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header className={styles.modalHeader} closeButton>
          <p>選択してください</p>
        </Modal.Header>
        <Modal.Body className={styles.modalbody}>
          <Row className="mb-2">
            <Col sm={12} className={styles.col}>
              <label>
                <input
                  type="checkbox"
                  checked={mainChecked}
                  onChange={handleMainCheckBoxChange}
                />
                すべて選択
              </label>
            </Col>
          </Row>
          <Row className={`${styles.borderTOP}`}>
            {arrayItems.map((item, index) => (
              <Col sm={6} key={index} className={styles.col}>
                <label>
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={(event) => {
                      handleCheckBoxChange(event, item);
                    }}
                  />
                  {item.label}
                </label>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          <Row className="w-100">
            <Col xs={12} sm={12} className={`${styles.buttons} text-center`}>
              <Button
                className={styles.button}
                onClick={clearAll}
                type="button"
              >
                クリア
              </Button>
              <Button className={styles.button} type="submit">
                選択して閉じる
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ArrayModal;
