import React, { useEffect, useState } from "react";

import { Button, Col, Modal, Row } from "react-bootstrap";

import { Rating } from "@mui/material";

import styles from "./ReviewItem.module.css";
import { useGetDoc } from "../../hooks/useGetDocs";
import StarRatings from "react-star-ratings";
import { getName } from "../../utils/Utils";
import moment from "moment";
import { Timestamp } from "firebase/firestore";

const ReviewItem = ({ review }) => {
  const { firebaseGetDoc, data, isPending, error } = useGetDoc();

  const [showReply, setShowReply] = useState(false);
  const [owner, setOwner] = useState();

  useEffect(() => {
    if (review.owner_id) {
      firebaseGetDoc("users", review.owner_id).then((res) => {
        setOwner(res);
      });
    }
  }, []);
  return (
    <>
      <Row className={styles.review}>
        <Col sm={3} className={styles.info}>
          <div>
            {owner && (
              <p>
                広島県{" "}
                <span className="text-nowrap">{getName(owner, 20)}様</span>
                <br></br>
              </p>
            )}
          </div>
        </Col>

        <Col sm={9} className={styles.reviewDetails}>
          <div className={styles.rating}>
            <StarRatings
              rating={review.rating}
              starDimension="20px"
              starSpacing="0px"
              starRatedColor="#FFC415"
            />
          </div>

          <div className={styles.reviewTextDetails}>
            <p className={styles.reviewText}>{review.review}</p>
            <p className={styles.reviewDate}>
              {moment(
                new Timestamp(
                  review.createdAt.seconds,
                  review.createdAt.nanoseconds
                ).toDate()
              ).format("YYYY/MM/DD HH:mm")}
            </p>
            {/* <div className="w-100 d-flex">
              <a className={styles.moreLink} href="#">
                ...もっと見る
              </a>
            </div> */}
            {!showReply && review?.replay && (
              <button
                onClick={() => {
                  setShowReply(true);
                }}
                className={styles.seeMoreReplyButton}
              >
                返信を見る
              </button>
            )}
            {showReply && (
              <>
                <ul className={styles.listItem}>
                  <li className="d-flex flex-column">
                    <div className={`w-100 ${styles.ReviewReplyText}`}>
                      <p className={`${styles.reviewText} mb-1`}>ブリーダーからの返信</p>
                      <p className={styles.reviewText}>
                        {review.replay[0]?.comment}
                      </p>
                    </div>
                    <p className={`${styles.reviewDate} ms-auto mb-0`}>
                      {moment(
                        new Timestamp(
                          review.replay[0]?.replayAt.seconds,
                          review.replay[0]?.replayAt.nanoseconds
                        ).toDate()
                      ).format("YYYY/MM/DD HH:mm")}
                    </p>
                  </li>
                </ul>
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ReviewItem;
