import { useState } from "react";
//firebase imports
import { auth, firebaseErrors } from "../firebase/config";
import {
  signInWithCustomToken,
  signInWithEmailAndPassword
} from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import { useToast } from "./useToast";
import { useLogout } from "./useLogout";
import { useGetDoc } from "./useGetDocs";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();
  const { logout } = useLogout();
  const { firebaseGetDoc } = useGetDoc();
  const toast = useToast();
  const login = async (email, password) => {
    setError(null);
    setIsPending(true);
    return await signInWithEmailAndPassword(auth, email, password)
      .then(async (res) => {
        if (res.user.emailVerified) {
          //we need to check it user group also
          const user = await firebaseGetDoc("users", res.user.uid);
          // console.log(user);
          if (user && user.group === "owner") {
            dispatch({ type: "LOGIN", payload: res.user });
            toast("success", "ログインしました");
            setIsPending(false);
            return res.user;
          } else {
            logout(false);
            setError("ログイン出来ません2");
          }
        } else {
          logout(false);
          setError("メールをご確認頂きアカウントを有効にしてください");
        }
        setIsPending(false);
        return false;
      })
      .catch((err) => {
        setError(firebaseErrors[err.code] || err.message);
        setIsPending(false);
        return false;
      });
  };

  const passwordLesslogin = (code) => {
    setError(null);
    setIsPending(true);
    signInWithCustomToken(auth, code)
      .then((res) => {
        if (res.user.emailVerified) {
          //we need to check it user group also
          const user = firebaseGetDoc("users", res.user.uid);
          if (user && user.group === "owner") {
            dispatch({ type: "LOGIN", payload: res.user });
            toast("success", "ログインしました");
            setIsPending(false);
            return res.user;
          } else {
            logout(false);
            setError("ログイン出来ません2");
          }
        } else {
          logout(false);
          setError("メールをご確認頂きアカウントを有効にしてください");
        }
        setIsPending(false);
      })
      .catch((err) => {
        setError(firebaseErrors[err.code] || err.message);
        setIsPending(false);
      });
  };

  return { error, isPending, login, passwordLesslogin };
};
