import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/Universal/CustomBreadcrumb";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import SearchPage from "./SearchPage";

const SearchContainer = () => {
  let [breadcrumb, setBreadcrumb] = useState(null);
  const { width } = useWindowDimensions();
  useEffect(() => {
    let breadcrumbItem = [];
    breadcrumbItem.push({ name: "サイト名", link: "/" });
    breadcrumbItem.push({ name: "子犬検索結果 ", link: "#" });
    breadcrumbItem.push({ name: "トイ・プードルの検索結果", link: "#" });
    breadcrumbItem.push({ name: "トイ・プードルの検索結果" });
    setBreadcrumb(breadcrumbItem);
  }, []);
  return (
    <>
      <>
        {width > 576 && (
          <>
            <CustomBreadcrumb Breadcrumb={breadcrumb} />
          </>
        )}
        <SearchPage breadcrumb={breadcrumb} />
      </>
    </>
  );
};

export default SearchContainer;
