import React, { useEffect } from "react";
import styles from "./SideBar.module.css";
import { Row, Placeholder } from "react-bootstrap";
import SideBarRatingItem from "./SideBarRatingItem";
import SideBarBanner from "./SideBarBanner";
import PopularDogBreed from "./PopularDogBreed";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useCollectionDocs } from "../../../hooks/useCollectionDocs";

const SideBar = () => {
  const { width } = useWindowDimensions();
  const {
    documents: SidebarBanners,
    isPending: SidebarPending,
    getMultipleDocs: getSidebarMultipleDocs
  } = useCollectionDocs();

  const {
    documents: reviews,
    isPending: reviewPending,
    getMultipleDocs: getReviewMultipleDocs
  } = useCollectionDocs();

  useEffect(() => {
    loadSidebarBanner();
    loadSidebarBanner();
  }, []);

  const loadSidebarBanner = () => {
    getReviewMultipleDocs({
      _collection: `microchip`,
      _query: { 1: "reviewAt,!=,null" },
      orderByField: "reviewAt",
      orderByOption: "desc",
      _limit: 3
    });

    getSidebarMultipleDocs({
      _collection: `sidebar_banners`,
      _query: false,
      orderByField: "type",
      orderByOption: "asc"
    });
  };

  return (
    <div className={styles.SideBar}>
      <p className={`${styles.heading} ps-sm-0`}>お客様の声</p>

      <div
        className={`bg-white ${
          width <= 576 ? styles.mobilePadding : "rounded-4 p-3"
        }`}
      >
        {reviews && Object.values(reviews).length > 0 && (
          <>
            {Object.values(reviews) &&
              Object.values(reviews).map((doc, i) => {
                return <SideBarRatingItem key={i} review={{...doc.review, reviewAt: doc.reviewAt}} />;
              })}
          </>
        )}

        {/* <a href="#" className={styles.showAllRatinLink}>
          評価が高いブリーダーを見る
          <span className="material-symbols-outlined">chevron_right</span>
        </a> */}
      </div>

      <div className={`mt-5 mt-sm-2 ${width < 576 ? "px-4 mb-5" : ""}`}>
        {/* {width > 576 && (
          <>
            <SideBarBanner data={{ type: 1 }} />
            <SideBarBanner data={{ type: 2 }} />
            <SideBarBanner data={{ type: 3 }} />
          </>
        )}

        {width <= 576 && (
          <>
            <SideBarBanner data={{ type: 4 }} />
            <SideBarBanner data={{ type: 5 }} />
            <SideBarBanner data={{ type: 6 }} />
          </>
        )} */}

        <>
          {!SidebarBanners && (
            <>
              <Placeholder animation="glow">
                {Array.from({ length: 3 }, (_, i) => (
                  <Placeholder
                    key={i}
                    className="w-100 my-2"
                    style={{ height: "200px" }}
                  />
                ))}
              </Placeholder>
            </>
          )}
          {SidebarBanners && (
            <>
              {SidebarBanners.map((item, i) => {
                if (item.image != "" && item.url != "") {
                  return <SideBarBanner key={i} data={item} />;
                }
              })}
            </>
          )}
        </>
      </div>

      {width > 576 && <PopularDogBreed />}
    </div>
  );
};

export default SideBar;
