import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Placeholder } from "react-bootstrap";

import { getRandomInt } from "../utils/Utils";
import { useAddressFormatter } from "../hooks/useAddressFormatter";

function AddressFormatter({ zip3, zip4, withArrow = true}) {
  const { addressFormatter, isPending } = useAddressFormatter();
  const [zipData, setZipData] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const data = await addressFormatter(zip3, zip4);
      setZipData(data);
    }
    fetchData();
  }, [zip3, zip4]);

  return (
    <>
      {!isPending && zipData && (
        <>
          {/* <Link to={""} className="text-reset"> */}
            {zipData.prefecture}
          {/* </Link> */}
          {withArrow && " > "}
          {/* <Link to={""} className="text-reset"> */}
            {zipData.city}
          {/* </Link> */}
          {withArrow && " > "}
          {/* <Link to={""} className="text-reset"> */}
            {zipData.town}
          {/* </Link> */}
        </>
      )}

      {isPending && (
        <>
          <Placeholder animation="glow">
            <Placeholder xs={getRandomInt(2, 3)} className="rounded-1" />{" "}
            <Placeholder xs={getRandomInt(2, 3)} className="rounded-1" />{" "}
            <Placeholder xs={getRandomInt(2, 3)} className="rounded-1" />
          </Placeholder>
        </>
      )}
    </>
  );
}

export default AddressFormatter;
