import React, { useEffect, useState } from "react";

import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { Col, Container, Placeholder, Row, Table } from "react-bootstrap";

import { Timestamp } from "firebase/firestore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { DescriptionOutlined, LocationOnOutlined } from "@mui/icons-material";

import PuppyShortCard from "./PuppyShortCard";
import { useReview } from "../../hooks/useReview";
import AddressFormatter from "../AddressFormatter";
import { dog_breed } from "../../utils/CatDogData";
import styles from "./PopularBreederItem.module.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import { generateRatingStarts, getName, getRandomInt } from "../../utils/Utils";

function BreederSortDetails({
  isPending,
  breeder = false,
  showRegDate = false,
  showChangeDate = false,
  withList = false,
  fulColumnWidth = false
}) {
  const {
    documents,
    isPending: isPendingList,
    getMultipleDocs
  } = useCollectionDocs();
  const { getReviewDetails, isPending: reviewPending } = useReview();

  const { width } = useWindowDimensions();

  const [reviewCount, setReviewCount] = useState(0);
  const [rating, setRating] = useState(0);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await getMultipleDocs({
      _collection: `users/${breeder.id}/microchip`,
      _query: {
        1: `sellPlatform,==,true`
      },
      orderByField: "sellPlatformAt"
      // _limit: 3
    });

    const { ratingAve, totalReview } = await getReviewDetails(breeder.id);
    setRating(ratingAve);
    setReviewCount(totalReview);
  };

  const [topBreeds, setTopBreeds] = useState([]);

  useEffect(() => {
    const breedCounts = {};

    if (documents === null || documents.length === 0) return;

    documents.forEach((doc) => {
      const breed = doc.mcData.animal_breed;
      if (breedCounts[breed]) {
        breedCounts[breed] += 1;
      } else {
        breedCounts[breed] = 1;
      }
    });

    const sortedBreeds = Object.entries(breedCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3);

    // console.log(sortedBreeds);
    setTopBreeds(sortedBreeds);
  }, [documents]);

  return (
    <>
      {isPending && (
        <Container className="bg-white p-lg-4 p-2 rounded-3">
          <Row className="placeholder-glow">
            <Col lg={12} className="d-sm-none d-block">
              <h5 className={styles.total}>
                <Placeholder xs={getRandomInt(1, 3)} className="rounded-1" />{" "}
                <Placeholder xs={getRandomInt(1, 3)} className="rounded-1" />
              </h5>
            </Col>
            <Col lg={3} xs={5}>
              <div className="ratio ratio-1x1">
                <Placeholder />
              </div>
            </Col>

            <Col lg={9} xs={7} className="pe-0">
              <Row className="d-none d-sm-flex">
                <Col lg={12}>
                  <h5 className={styles.total}>
                    <Placeholder
                      xs={getRandomInt(1, 3)}
                      className="rounded-1"
                    />{" "}
                    <Placeholder
                      xs={getRandomInt(1, 3)}
                      className="rounded-1"
                    />
                  </h5>
                </Col>
                <Col xxl={4} lg={5}>
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td className="fw-bold  text-nowrap">総合評価</td>
                        <td className="fw-normal placeholder">
                          <StarRatings
                            rating={getRandomInt(3, 5)}
                            starDimension="22px"
                            starSpacing="0px"
                            starRatedColor="#FAAF00"
                          />

                          <span className="ms-1">{breeder.rating || 5}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold  text-nowrap">口コミ数</td>
                        <td className="fw-normal">
                          <Placeholder
                            xs={getRandomInt(3, 5)}
                            className="rounded-1"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col xxl={8} lg={7}>
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td className="fw-bold w-30">取扱犬種</td>
                        <td className="fw-normal">
                          <Placeholder
                            xs={getRandomInt(2, 4)}
                            className="rounded-1"
                          />{" "}
                          <Placeholder
                            xs={getRandomInt(2, 4)}
                            className="rounded-1"
                          />{" "}
                          <Placeholder
                            xs={getRandomInt(2, 4)}
                            className="rounded-1"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold w-30 text-nowrap">
                          ワンちゃん一覧
                        </td>
                        <td className="fw-normal">
                          <Placeholder xs={5} className="rounded-1" />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col xxl={4} lg={5} className="cmn-green-bg mb-2">
                  <p className="text-center fw-bold mb-0 py-2">
                    <FontAwesomeIcon icon={faLocationDot} /> 犬舎住所 (見学場所)
                  </p>
                </Col>
                <Col xxl={8} lg={7}>
                  <p className="fw-normal mb-0 py-2">
                    <Placeholder
                      xs={getRandomInt(4, 8)}
                      className="rounded-1"
                    />
                  </p>
                </Col>

                <Col xxl={4} lg={5} className="cmn-blue-bg">
                  <p className="text-center fw-bold mb-0 py-2">
                    <FontAwesomeIcon icon={faFileLines} /> 動物取扱業登録番号
                  </p>
                </Col>
                <Col xxl={8} lg={7}>
                  <p className="fw-normal mb-0 py-2">
                    <Placeholder
                      xs={getRandomInt(2, 3)}
                      className="rounded-1"
                    />{" "}
                    <Placeholder
                      xs={getRandomInt(2, 3)}
                      className="rounded-1"
                    />{" "}
                    <Placeholder
                      xs={getRandomInt(2, 3)}
                      className="rounded-1"
                    />
                  </p>
                </Col>
              </Row>

              <Row className="d-sm-none d-flex pe-3">
                <Col xs={12} className="ps-0  ">
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td className="fw-normal placeholder p-0">
                          {generateRatingStarts(breeder.rating || 5).map(
                            (star, i) => {
                              return <span key={i}>{star}</span>;
                            }
                          )}
                          <span className="ms-1">{breeder.rating || 5}</span>
                        </td>
                        <td className="fw-normal w-30 p-0">
                          <Placeholder xs={12} className="rounded-1" />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>

                <Col xs={12} className="cmn-green-bg  fs-7">
                  <p className=" fw-bold mb-0">
                    <FontAwesomeIcon icon={faLocationDot} /> 犬舎住所 (見学場所)
                  </p>
                </Col>
                <Col xs={12}>
                  <Placeholder xs={getRandomInt(4, 8)} className="rounded-1" />
                </Col>

                <Col xs={12} className="cmn-blue-bg  fs-7">
                  <p className=" fw-bold mb-0">
                    <FontAwesomeIcon icon={faFileLines} /> 動物取扱業登録番号
                  </p>
                </Col>
                <Col xs={12}>
                  <Placeholder xs={getRandomInt(4, 8)} className="rounded-1" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}

      {!isPending && breeder && (
        <div
          className={`${styles.PopularBreederItem} ${
            fulColumnWidth ? styles.fullColumnWidth : ""
          } position-relative`}>
          <Row>
            {width <= 576 && (
              <Col
                xs={{
                  span: 11,
                  offset: 1
                }}
                className="ms-2">
                <h5 className={styles.totalH}>
                  {getName(breeder)} <span>ブリーダー</span>
                </h5>
              </Col>
            )}
            <Col
              sm={3}
              xs={5}
              className={`${width <= 576 ? "pe-0" : ""} ${
                width > 576 && fulColumnWidth ? styles.w20 : ""
              }`}>
              <Link
                to={`/breeder/${breeder.lookforusername}`}
                className="text-decoration-none text-reset">
                <div className="ratio ratio-1x1">
                  <img
                    src={breeder?.img || "/assets/img/PopularBreederDog.png"}
                    className="object-fit-cover p-2"
                    alt="breeder"
                  />
                </div>
              </Link>
            </Col>

            <Col
              xs={7}
              sm={7}
              xl={9}
              className={`${width <= 576 ? "ps-1" : "d-flex flex-column"} ${
                width > 576 && fulColumnWidth ? styles.w80 : ""
              }`}>
              {width > 576 && (
                <>
                  <Link
                    to={`/breeder/${breeder.lookforusername}`}
                    className="text-decoration-none text-reset">
                    <h5 className={styles.totalH}>
                      {getName(breeder)} <span>ブリーダー</span>
                    </h5>
                  </Link>
                  <div className={`${styles.handledBreeds} fs-6`}>
                    取扱犬種：
                    {topBreeds
                      .map(
                        ([breedno, count]) =>
                          _.find(dog_breed, ["value", breedno])?.label
                      )
                      .join("、")}
                  </div>
                  {!withList && <div className="fs-6 mt-2">ワンちゃん一覧　{documents?.length}件</div>}
                </>
              )}
              <div
                className={`d-flex mb-1 mb-sm-1 mb-xxl-2 align-items-center`}>
                <p
                  className={`${styles.Rating} d-flex align-items-center mb-0`}>
                  <StarRatings
                    rating={rating}
                    starDimension={width > 576 ? "25px" : "22px"}
                    starSpacing="0px"
                    starRatedColor="#FAAF00"
                  />
                  <span className={`${styles.rating} mt-1 ms-2`}>
                    {rating ? parseFloat(rating).toFixed(1) : "0.0"}
                  </span>
                </p>
                <p className={styles.right}>
                  {width > 576 && <>{reviewCount || 0}件</>}

                  {width <= 576 && <>({reviewCount || 0}件)</>}
                </p>
              </div>
              <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center mt-1">
                <p className={styles.leftWithColor1}>
                  <LocationOnOutlined
                    style={{
                      color: "var(--cmn-text-color-ash)",
                      fontSize: "1.2rem"
                    }}
                  />
                  犬舎住所（見学場所）
                </p>
                <p className={styles.rightWitchColor1}>
                  <AddressFormatter zip3={breeder.zip3} zip4={breeder.zip4} />
                </p>
              </div>
              <div className="d-flex flex-column flex-sm-row mt-1 align-items-start align-items-sm-center">
                <p className={styles.leftWithColor2}>
                  <DescriptionOutlined
                    className="me-1"
                    style={{
                      color: "var(--cmn-text-color-ash)",
                      fontSize: "1.2rem"
                    }}
                  />
                  動物取扱業登録番号
                </p>
                <p className={styles.rightWitchColor2}>
                  {" "}
                  {breeder.animal_handler_business_type1_slot1}
                  {fulColumnWidth && width > 576 && (
                    <>
                      {showRegDate && breeder.createdAt && (
                        <span className="text-nowrap">
                          　登録:
                          {moment(
                            new Timestamp(
                              breeder.createdAt.seconds,
                              breeder.createdAt.nanoseconds
                            ).toDate()
                          ).format("YYYY/MM/DD")}
                        </span>
                      )}
                      {showChangeDate && breeder.updateAt && (
                        <span className="text-nowrap">
                          　有効期限:
                          {moment(
                            new Timestamp(
                              breeder.updateAt.seconds,
                              breeder.updateAt.nanoseconds
                            ).toDate()
                          ).format("YYYY/MM/DD")}
                        </span>
                      )}
                    </>
                  )}
                </p>
              </div>
            </Col>
          </Row>
          {withList && (
            <Row className="mt-2 mt-sm-4">
              <Col sm={12} className={`${styles.bgPink} align-items-center`}>
                <h6 className={styles.total}>
                  {getName(breeder)} <span>ブリーダーのワンちゃん一覧</span>
                </h6>
                {documents?.length > 3 && width > 576 && (
                  <a href="#">
                    <span className="d-flex align-items-center">
                      すべて見る({documents?.length}件)
                      <ChevronRightIcon />
                    </span>
                  </a>
                )}
              </Col>

              <Col xxl={{ span: 12 }} md={{ span: 12, offset: 0 }}>
                <Row
                  className={`${styles.items} ${
                    width <= 576 ? styles.ShortPadding : "px-2"
                  }`}>
                  {documents &&
                    documents.slice(0, 3).map((animalData, i) => (
                      <div
                        key={i}
                        className={`mt-2 mt-sm-3 ${
                          width <= 576 ? "px-2" : ""
                        } ${styles.PuppyCardContainer}`}>
                        <PuppyShortCard
                          animalData={animalData}
                          showType={true}
                        />
                      </div>
                    ))}

                  {documents?.length > 3 && width <= 576 && (
                    <Col xs={12}>
                      <a className={styles.mobileLINK} href="#">
                        <span className="d-flex align-items-center">
                          すべて見る({documents?.length}件) <ChevronRightIcon />
                        </span>
                      </a>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
}

export default BreederSortDetails;
