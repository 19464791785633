import React, { useState } from "react";
import styles from "./TopContent.module.css";
import { Col, Row } from "react-bootstrap";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import TotalCountComponent from "../template/Header/TotalCountComponent";

const TopContent = () => {
  const [totalRegisteredAnimal, setTotalRegisteredAnimal] = useState("◯◯◯");
  const { width } = useWindowDimensions();
  return (
    <div className={styles.topContent}>
      {width <= 576 && (
        <>
          <div className={styles.CountContainer}>
            <TotalCountComponent />
          </div>
        </>
      )}
      <Row className={`h-100 ${width <= 576 ? ` ${styles.mobileRow}` : ""}`}>
        <Col sm={12} md={6}>
          <h2 className={`${styles.h2} mt-sm-5 `}>
            さあ、見つけに行こう、新しい家族
          </h2>
          <h1 className={`${styles.h1} mt-1`}>
            万全のサポートの <span className={styles.lookfor}>LOOKFOR</span>
          </h1>
        </Col>

        <Col
          sm={12}
          md={6}
          className={` h-100 my-auto d-flex ${
            width > 576 ? "flex-column" : "flex-row"
          } justify-content-center `}
        >
          <div className={`${styles.cap} mx-auto ms-sm-auto me-sm-0`}>
            <img src="/assets/img/cap1.png" alt="" className="w-100" />
          </div>

          <div className={`${styles.cap} mx-auto ms-sm-auto me-sm-0`}>
            <p>
              <span>最低価格</span>に
            </p>
            <p>挑戦</p>
          </div>

          <div className={`${styles.cap} mx-auto ms-sm-auto me-sm-0`}>
            <p>
              <span>先天性障害</span>を
            </p>
            <p>
              <span>1年間保証</span>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TopContent;
