import { useState, useEffect } from "react";
import { db } from "../firebase/config";
import { doc, getDoc, query, where } from "firebase/firestore";

export const useGetBreederWithid = () => {
  const [documents, setDocuments] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const getBreederByIds = async (collectionName, ids) => {
    setIsPending(true);
    setError(null);
    setDocuments([]);

    try {
      const results = await Promise.all(
        ids.map(async (id) => {
          const docRef = doc(db, collectionName, id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            if (data.group === "breederpet") {
              return { ...data, id: docSnap.id };
            } else {
              // If the 'group' field is not 'breederpet', return null
              return null;
            }
          } else {
            return null;
          }
        })
      );

      setDocuments(results.filter((result) => result !== null));
    } catch (error) {
      setError(error.message);
    }

    setIsPending(false);
  };

  return { documents, isPending, error, getBreederByIds };
};
