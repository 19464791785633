import { useState } from "react";

import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Form,
  InputGroup,
  Modal,
  Spinner
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faRightToBracket,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";

import { useLogin } from "../../hooks/useLogin";

export default function LoginModal(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { error, isPending, login } = useLogin();

  const handelSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <>
      <Modal
        style={{ zIndex: 99999999999996 }}
        show={props.show}
        onHide={() => props.modalShowController(false)}
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
      >
        <Form onSubmit={handelSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h3>ログイン</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && (
              <Alert variant="danger" dismissible className="text-center">
                {error && <p className="m-0">{error}</p>}
              </Alert>
            )}

            <Form.Group className="mb-3">
              <Form.Label>
                Eメールアドレス <span className="text-danger">※</span>
              </Form.Label>
              <Form.Control
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                パスワード <span className="text-danger">※</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <InputGroup.Text
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={!showPassword ? faEyeSlash : faEye} />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <p className="text-center">
              <Link to={`${process.env.REACT_APP_MCR_URL}forget-password`}>
                &gt;&gt;パスワードをお忘れの方はこちらをクリックしてください。
              </Link>
            </p>
            <h5 className="text-center">
              こちらのサイトにご登録をまだされていない方は <br />
              下記よりご登録お願い致します。
            </h5>
            <div className="text-center mt-3">
              <Link
                className="btn btn-secondary"
                to={`${process.env.REACT_APP_MCR_URL}signup?group=owner&returnurl=${process.env.REACT_APP_KOINU_URL}&returnsite=${process.env.REACT_APP_KOINU_TITLE}`}
              >
                <FontAwesomeIcon icon={faUserPlus} /> <b>新規ご登録</b>
              </Link>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => props.modalShowController(false)}
            >
              閉じる
            </Button>
            {!isPending && (
              <Button variant="primary" type="submit">
                <FontAwesomeIcon icon={faRightToBracket} /> <b>ログイン</b>
              </Button>
            )}

            {isPending && (
              <Button variant="primary" disabled>
                <FontAwesomeIcon icon={faRightToBracket} /> <b>ログイン </b>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
