import React, { useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { FavoriteBorderOutlined } from "@mui/icons-material";

import LoginModal from "../login/LoginModal";
import useFavourite from "../../hooks/useFavourite";
import { Spinner } from "react-bootstrap";

const FavPetIcon = (props) => {
  const {
    isReady,
    isFavourite,
    isPending,
    addToFavourite,
    removeFromFavourite,
    totalFav
  } = useFavourite(props.id);
  const [showLoginModal, setShowLoginModal] = useState(false);

  return (
    <>
      {isReady && !isPending && (
        <>
          {!isFavourite ? (
            <>
              <FavoriteBorderOutlined
                className={props.className}
                onClick={addToFavourite}
              />
            </>
          ) : (
            <>
              <FavoriteIcon
                className={props.className}
                onClick={removeFromFavourite}
              />
            </>
          )}
        </>
      )}

      {isReady && isPending && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          // className={props.className}
        />
      )}

      {!isReady && (
        <>
          <FavoriteBorderOutlined
            className={props.className}
            onClick={() => {
              setShowLoginModal(true);
            }}
          />
        </>
      )}

      <LoginModal
        show={showLoginModal && !isReady}
        onHide={() => {
          setShowLoginModal(false);
        }}
        modalShowController={setShowLoginModal}
      />
    </>
  );
};

export default FavPetIcon;
