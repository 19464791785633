import { db } from "../firebase/config";
//firebase imports
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from "firebase/firestore";
import { useState } from "react";

export const useGetDoc = () => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const firebaseGetDoc = async (c, id) => {
    setData(null);
    setIsPending(true);
    setError(null);
    let ref = doc(db, c, id);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      setData({ ...docSnap.data() });
      setIsPending(false);
      return { ...docSnap.data() };
    } else {
      setError("NO_DOC");
    }
  };

  const getSingleDocWithQuery = async (c, q) => {
    setData(null);
    setIsPending(true);
    setError(null);
    let ref = collection(db, c);

    if (q) {
      q.forEach((qitem) => {
        ref = query(ref, where(...qitem));
      });
    }

    const docSnap = await getDocs(ref);

    let results = [];
    docSnap.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id });
    });

    if (results.length) {
      setIsPending(false);
      setData(results);
      // console.log(results);
      return results;
    } else {
      setIsPending(false);
      setError("NO_DOC");
      // console.log("NO_DOC");
      return false;
    }
  };

  return { firebaseGetDoc, getSingleDocWithQuery, data, isPending, error };
};
