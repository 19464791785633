import { db, firebaseErrors } from "../firebase/config";
//firebase imports
import { collection, query, where, getDocs, limit } from "firebase/firestore";
import { useState, useEffect } from "react";

function useQueryDocs() {
  const [docData, setDocData] = useState(null);
  const [pending, setPending] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch all matching items
  const fetchItems = async (collectionName, queryObject, itemLimit = null) => {
    try {
      const collectionRef = collection(db, collectionName); // Use Firestore collection function
      let firestoreQuery = collectionRef;

      // Build the query based on the queryObject
      for (const key in queryObject) {
        if (queryObject.hasOwnProperty(key)) {
          const value = queryObject[key];
          firestoreQuery = query(firestoreQuery, where(key, "==", value));
        }
      }
      if (itemLimit) {
        firestoreQuery = query(firestoreQuery, limit(itemLimit));
      }
      const snapshot = await getDocs(firestoreQuery); // Use Firestore getDocs function

      if (!snapshot.empty) {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setDocData(data);
        setPending(false);
        return data;
      } else {
        // No matching documents found
        setPending(false);
        setDocData([]);
        return [];
      }
    } catch (err) {
      setError(err);
      setPending(false);
      return [];
    }
  };

  return { docData, pending, error, fetchItems };
}

export default useQueryDocs;
