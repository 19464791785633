import React from "react";
import styles from "./PopularDogBreedItem.module.css";
import { Link } from "react-router-dom";

const PopularDogBreedItem = ({ data, pos, fulWidth }) => {
  return (
    <Link
      to={"/breed/" + data.value}
      className={`${styles.item} ${fulWidth ? styles.fulWidth : ""} mt-sm-1`}
    >
      <div className={`ratio ratio-4x3 ${styles.imageContainer}`}>
        <img
          src={data.image || "/assets/img/PopularDogDummy.png"}
          alt=""
          className={`w-100 h-100 object-fit-cover ${styles.image}`}
        />
      </div>

      <p className={styles.breedName}>{data.label}</p>
      <p
        className={`${styles.badge} ${pos == 1 ? styles.badgeFirst : ""} ${
          pos == 2 ? styles.badgeSecond : ""
        } ${pos == 3 ? styles.badgeThird : ""} `}
      >
        {pos}
      </p>
    </Link>
  );
};

export default PopularDogBreedItem;
