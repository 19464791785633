import React from "react";
import styles from "./SideBarBanner.module.css";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const SideBarBanner = ({ data }) => {
  //data.type=1,4 => 1*1
  //data.type=2,3 => 2.5*1
  const { width } = useWindowDimensions();

  return (
    <>
      {(data.type == 1 || data.type == 4) && (
        <div className={`${width <= 576 ? "mx-5" : ""}`}>
          <div
            className={`${styles.bannerContent} ratio ratio-1x1 my-3 ${styles.customAspectAatio11}`}
            onClick={(e) => {
              e.preventDefault();
              window.open(data.url);
            }}
          >
            <img src={data.image} className="w-100" />
          </div>
        </div>
      )}

      {(data.type == 2 || data.type == 3) && (
        <div className="mx-2 mx-sm-0">
          <div
            className={`${styles.bannerContent} ${styles.customAspectAatio251} my-3`}
            onClick={(e) => {
              e.preventDefault();
              window.open(data.url);
            }}
          >
            <div className={styles.content}>
              <img src={data.image} className="w-100 h-100 object-fit-cover" />
            </div>
          </div>
        </div>
      )}

      {/* {data.type == 5 && (
        <div className="mx-2 mx-sm-0">
          <div
            className={`${styles.bannerContent} ${styles.customAspectAatio641} my-3`}
          >
            <div className={styles.content}>
              <img
                src="/assets/img/sidebarBanner.png"
                className="w-100 h-100 object-fit-cover"
              />

              <p>
                正方形バナー<br></br>
                320x50
              </p>
            </div>
          </div>
        </div>
      )}

      {data.type == 6 && (
        <div className="mx-2 mx-sm-0">
          <div
            className={`${styles.bannerContent} ${styles.customAspectAatio321} my-3`}
          >
            <div className={styles.content}>
              <img
                src="/assets/img/sidebarBanner.png"
                className="w-100 h-100 object-fit-cover"
              />

              <p>
                正方形バナー<br></br>
                320x100
              </p>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default SideBarBanner;
