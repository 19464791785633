import React, { useEffect, useRef } from "react";

import sjcl from "sjcl";
import { useParams } from "react-router-dom";

import { useLogin } from "../hooks/useLogin";
import { useGetDoc } from "../hooks/useGetDocs";
import { useDeleteDocs } from "../hooks/useDeleteDocs";
import { useAuthContext } from "../hooks/useAuthContext";

export default function LoginWithCode() {
  const params = useParams();
  const { deleteDocument } = useDeleteDocs();
  const { user } = useAuthContext();
  const { error, isPending, passwordLesslogin } = useLogin();
  const { firebaseGetDoc, isPending: isPending2 } = useGetDoc();

  const key = process.env.REACT_APP_SJCL_KEY;
  const flag = useRef(true);

  useEffect(() => {
    if (flag.current) {
      flag.current = false;
      autoLogin();
    }
  }, []);

  const autoLogin = async () => {
    if (!user) {
      const secrect = await firebaseGetDoc("temp_otp", params.code);
      if (secrect) {
        const data = sjcl.decrypt(key, secrect.data);
        passwordLesslogin(data);

        //delete secrect
        deleteDocument("temp_otp", params.code);
      }
    } else {
      //delete secrect
      deleteDocument("temp_otp", params.code);
    }
  };
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {error && <>can not automatically logging you in...</>}

      {(isPending || isPending2) && <>Automatically logging you in...</>}
    </>
  );
}
