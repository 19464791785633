import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PetInfo from "./PetInfo";
import CustomBreadcrumb from "../../components/Universal/CustomBreadcrumb";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const PetDetails = () => {
  const params = useParams();
  let [breadcrumb, setBreadcrumb] = useState(null);
  const { width } = useWindowDimensions();
  useEffect(() => {
    let breadcrumbItem = [];
    breadcrumbItem.push({ name: "サイト名", link: "/" });
    breadcrumbItem.push({ name: "子犬検索結果 ", link: "#" });
    breadcrumbItem.push({ name: "トイ・プードルの検索結果", link: "#" });
    breadcrumbItem.push({ name: "登録ID: " + params.id });
    setBreadcrumb(breadcrumbItem);
  }, [params]);
  return (
    <>
      {width > 576 && (
        <>
          <CustomBreadcrumb Breadcrumb={breadcrumb} />
        </>
      )}
      <PetInfo petID={params.id} breadcrumb={breadcrumb} />
    </>
  );
};

export default PetDetails;
