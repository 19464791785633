import React, { useEffect, useState } from "react";
import styles from "./AnimalItem.module.css";
import { genderWithIcon } from "../../utils/Utils";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import useWindowDimensions from "../../../src/hooks/useWindowDimensions";
import AddressFormatter from "../AddressFormatter";
import { Link } from "react-router-dom";
import FavPet from "../Universal/FavPet";
import FavPetIcon from "../Universal/FavPetIcon";

const AnimalItem = ({
  data,
  type = "card",
  show = "scroll",
  animalItemContainerRef = null
}) => {
  const [totalFavCount, setTotalFavCount] = useState(0);
  const { width } = useWindowDimensions();
  const convertDate = (inputDateString) => {
    const inputDate = new Date(inputDateString);

    if (isNaN(inputDate.getTime())) {
      return "";
    }

    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(inputDate.getDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
  };

  useEffect(() => {
    setTimeout(() => {
      if (animalItemContainerRef && animalItemContainerRef.current) {
        if (width > 576 || (type !== "card" && width <= 576)) {
          makeEqualHeightofCards();
        } else {
          const container = animalItemContainerRef.current.parentNode;
          const cards = container.getElementsByClassName("AnimalCard");
          for (let i = 0; i < cards.length; i++) {
            cards[i].style.height = `auto`;
          }
        }
      }
    }, 1000);
  }, [type, width]);

  // useEffect(() => {
  //   if (animalItemContainerRef && type != "card") {
  //     const container = animalItemContainerRef.current.parentNode;
  //     const cards = container.getElementsByClassName("AnimalCard");
  //     for (let i = 0; i < cards.length; i++) {
  //       cards[i].style.height = `auto`;
  //     }
  //     if (width > 576 || (type !== "card" && width <= 576)) {
  //       makeEqualHeightofCards();
  //     } else {
  //     }
  //   }
  // }, [width, data]);

  const makeEqualHeightofCards = () => {
    const container = animalItemContainerRef.current.parentNode;
    const cards = container.getElementsByClassName("AnimalCard");
    let maxHeight = 0;

    for (let i = 0; i < cards.length; i++) {
      cards[i].style.height = `auto`;
    }

    for (let i = 0; i < cards.length; i++) {
      const cardHeight = cards[i].clientHeight;
      if (cardHeight > maxHeight) {
        maxHeight = cardHeight;
      }
    }

    // Set the height of all AnimalCard components to the tallest one
    for (let i = 0; i < cards.length; i++) {
      cards[i].style.height = `${maxHeight}px`;
    }
  };
  return (
    <div className="position-relative AnimalCard">
      <div
        className={`${styles.animalItem} ${
          width <= 576 && type == "longcard"
            ? styles.mobileLongCardContent
            : styles.mobileCardContent
        }
        
        ${show == "grid" ? styles.gridTypeShow : ""}
        
        h-100`}>
        <div className={`${styles.image}`}>
          <Link
            to={`/pet/${data.animalID}`}
            className="text-decoration-none text-reset">
            <div className={`ratio ratio-1x1`}>
              <img src={data.image} className={`w-100`} />
            </div>
          </Link>
        </div>
        <div className={`${width <= 576 ? styles.mobileContent : ""} `}>
          <Link
            to={`/pet/${data.animalID}`}
            className="text-decoration-none text-reset">
            <div className="d-flex">
              <div
                className={`${styles.genderTag} ${
                  data.gender == 1 ? styles.maleTag : styles.femaleTag
                }`}>
                {genderWithIcon(data.gender)}
              </div>

              {data.product_type === "里親" && (
                <>
                  <div className={`${styles.parentTag} ms-2`}>
                    {data.product_type}
                  </div>
                </>
              )}
            </div>
            <div className="w-100 mt-1 mb-1 mb-sm-2">
              <h6 className={styles.animalType}>{data.animal_type}</h6>
              <p className={styles.birthday}>
                {convertDate(data.birthday)}生まれ
              </p>
            </div>
            <div className="w-100 d-flex">
              <table className="w-100">
                <tbody>
                  <tr>
                    <td className={styles.type}>毛色</td>
                    <td className={styles.data}>{data.color}</td>
                  </tr>
                  <tr>
                    <td className={styles.type}>犬舎</td>
                    <td className={styles.data}>
                      <AddressFormatter zip3={data.zip3} zip4={data.zip4} />
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.type}>PR</td>
                    <td className={styles.data}>{data.prefecture}</td>
                  </tr>
                  {data.price && (
                    <tr className="">
                      <td
                        className={`${styles.type} ${styles.priceRowPadding}`}>
                        価格
                      </td>
                      <td
                        className={`${styles.data} ${styles.price} ${styles.priceRowPadding}`}>
                        {data.price.toLocaleString()} 円（税込）
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Link>
        </div>

        {data.tag == "new" && <div className={styles.new}>{data.tag}!</div>}
        {data.tag == "hot" && <div className={styles.hot}>{data.tag}!</div>}

        <div sm={12} className="text-end mt-auto">
          {data.animalID && (
            <FavPetIcon
              id={data.animalID}
              className={`${styles.favIcon} ${
                width < 576 && type == "card" ? styles.mobileFavCard : ""
              }`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AnimalItem;
