import { useEffect } from "react";

import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaw } from "@fortawesome/free-solid-svg-icons";

import ProgressStep from "../../components/progressStep/ProgressStep";

export default function InquiryComplete() {
  useEffect(() => {
    localStorage.removeItem("inquiryData");
    localStorage.removeItem("petDatils");
    localStorage.removeItem("breederDatils");
  }, []);

  return (
    <>
      <div className="cmn-bg">
        <div className="cmn-pastel-yellow-bg">
          <Container>
            <p className="yollow-title fs-5 fw-bold cmn-text-color py-3">
              <FontAwesomeIcon icon={faPaw} /> 見学希望・お問い合わせフォーム
            </p>
          </Container>
        </div>
        <Container>
          <Row className="justify-content-center mt-5 mb-5">
            <ProgressStep
              props={[
                { value: "内容入力", link: "#" },
                { value: "内容確認", link: "#" },
                { value: "送信完了", link: "#" }
              ]}
              activeNo={3}
            />
          </Row>
          <Row className="justify-content-center cmn-text-color-ash">
            <Col lg={11}>
              <div className="p-4 cmn-pastel-yellow-bg mx-5 text-center">
                見学希望・お問い合わせいただきありがとうございます。送信が完了いたしました。
                <br />
                ご登録いただいるメールアドレスから内容のご確認ができますのでよろしくお願いします。
              </div>
              <br />
              <br />
              <Row className="justify-content-center pb-4">
                <div className="text-center mt-3">
                  <Link to="/" className="cmn-yollow-btn mb-2 py-3 shadow">
                    <b>TOPに戻る</b>
                  </Link>
                </div>
              </Row>

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
