import { useState } from "react";
import { useAggregation } from "./useAggregation";

export const useReview = () => {
  const { averageWithQuery, countWithQuery } = useAggregation();

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const getReviewDetails = async (breederID) => {
    const ratingAve = await averageWithQuery(
      `users/${breederID}/reviews/`,
      false,
      "rating"
    );

    const totalReview = await countWithQuery(
      `users/${breederID}/reviews/`,
      false
    );

    if (ratingAve && totalReview) {
      setIsPending(false);
      return { ratingAve, totalReview };
    } else {
      setIsPending(false);
      setError("NO_DOC");
      // console.log("NO_DOC");
      return false;
    }
  };

  return { getReviewDetails, isPending, error };
};
