import React, { useState } from "react";

import { Col, Row } from "react-bootstrap";

import { Rating } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DescriptionOutlined, LocationOnOutlined } from "@mui/icons-material";
import {
  faAngleRight,
  faFileCircleExclamation,
  faLocationDot
} from "@fortawesome/free-solid-svg-icons";

import PuppyShortCard from "./PuppyShortCard";
import styles from "./PopularBreederItem.module.css";
import useWindowDimensions from "../../../src/hooks/useWindowDimensions";
import BreederSortDetails from "./BreederSortDetails";

const PopularBreederItem = ({
  breeder = false,
  withList = false,
  fulColumnWidth = false
}) => {
  const [total, setTotal] = useState("◯◯◯◯");
  const [rating, setRating] = useState(5);
  const [totalReview, setToalReview] = useState(100);
  const [img, setImg] = useState("/assets/img/PopularBreederDog.png");
  const [address, setAddress] = useState("千葉県山武郡横芝光町母子");
  const [phone, setPhone] = useState("123-456789");
  const [regDate, setRegDate] = useState("2016/10/10");
  const [expDate, setExpDate] = useState("2029/05/30");

  const [totalPuppies, setTotalPuppies] = useState("◯◯ ◯◯");
  const [handledBreeds, setHandleBreeds] =
    useState("ポメラニアン、マルチーズ、チワワ");
  const [totalCount, setTotalCount] = useState(5);
  const { width } = useWindowDimensions();

  return (
    <BreederSortDetails
      breeder={breeder}
      showRegDate={true}
      showChangeDate={true}
      withList={withList}
    />
  );
};

export default PopularBreederItem;
