import React, { useEffect, useRef, useState } from "react";

import _ from "lodash";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";

import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";
import ViewStreamSharpIcon from "@mui/icons-material/ViewStreamSharp";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";

import AnimalItem from "../Home/AnimalItem";
import styles from "./AnimalItemListGrid.module.css";
import { dog_breed, dog_color } from "../../utils/CatDogData";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import moment from "moment";

export const AnimalItemListGrid = ({ breederName, id, limit = true }) => {
  const [animalList, setAnimalList] = useState([]);
  const [showAnimalCardGrid, setShowAnimalCardGrid] = useState(false);
  const { width } = useWindowDimensions();
  const { documents, isPending, getMultipleDocs } = useCollectionDocs();
  const animalItemContainerRef = useRef(null);
  const [orderByOption, setOrderByOption] = useState("desc");
  let totalRenderedItem = 0;
  //Using this for making the equalheight.
  const [countSummary, setCountSummary] = useState({
    total: 0,
    start: 1,
    end: 10
  });
  useEffect(() => {
    let array = new Array();
    if (Array.isArray(documents)) {
      setCountSummary({
        ...countSummary,
        total: documents.length
      });

      if (limit) {
        if (width <= 576) {
          if (documents.length > 5) {
            setCountSummary({
              ...countSummary,
              end: 5,
              total: documents.length
            });
          } else {
            setCountSummary({
              ...countSummary,
              end: documents.length,
              total: documents.length
            });
          }
        } else {
          if (documents.length > 10) {
            setCountSummary({
              ...countSummary,
              end: 10,
              total: documents.length
            });
          } else {
            setCountSummary({
              ...countSummary,
              end: documents.length,
              total: documents.length
            });
          }
        }
      } else {
        setCountSummary({
          ...countSummary,
          end: documents.length,
          total: documents.length
        });
      }
      let count = documents.map((element, i) => {
        if (limit) {
          if (width <= 576 && i > 4) {
            return;
          }
          if (i > 9) {
            return;
          }
        }
        let animalObject = {
          id: element.id,
          image: "/assets/img/animalPreview.png",
          // tag: "new",
          gender: element.mcData.animal_gender == "メス" ? 2 : 1,
          product_type: element.product_type, //Optional
          animal_type:
            element.mcData.animal_breed !== "99"
              ? _.find(dog_breed, ["value", element.mcData.animal_breed])?.label
              : element.mcData.animal_breed_other,

          birthday:
            moment(element.mcData.animal_birthDay).format("YYYY年MM月DD日") ||
            "",
          color:
            element.mcData.animal_color !== "99"
              ? _.find(dog_color, ["value", element.mcData.animal_color])?.label
              : element.mcData.animal_color_other,
          zip3: element.sellPlatform_zip3,
          zip4: element.sellPlatform_zip4,
          shortInfo: element.prPoint || "",
          price: element.sellPlatformPrice
            ? parseInt(element.sellPlatformPrice).toLocaleString("en-US")
            : null,
          animalID: element.animalID
        };

        if (Array.isArray(element.images) && element.images.length >= 0) {
          animalObject.image = element.images[0];
        }

        if (element.mcData.animal_gender) {
          if (element.mcData.animal_gender == "メス") {
            animalObject.gender = 2;
          } else {
            animalObject.gender = 1;
          }
        }

        array.push(animalObject);
      });
      setAnimalList(array);
    } else {
      loadData();
    }
  }, [documents]);

  useEffect(() => {
    loadData();
  }, [width, orderByOption]);

  const loadData = () => {
    getMultipleDocs({
      _collection: `users/${id}/microchip`,
      _query: {
        1: `sellPlatform,==,true`
      },
      orderByField: "createdAt",
      orderByOption: orderByOption
    });
  };

  const updateorderBy = (e) => {
    e.preventDefault();
    if (orderByOption == "asc") {
      setOrderByOption("desc");
    } else {
      setOrderByOption("asc");
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col sm={12}>
            <h4 className={`m-0 ${styles.heading}`}>
              {breederName} ブリーダーの販売中のワンちゃん一覧
            </h4>
          </Col>
        </Row>
      </Container>

      {width > 576 && (
        <Container className="mt-3 mt-sm-2 mb-1">
          <Row>
            <Col sm={6} className="d-flex align-items-center">
              <p className={styles.totalSummeryText}>
                全{countSummary.total.toLocaleString()}件中　
                {countSummary.start} ～ {countSummary.end}
                件目を表示
              </p>
            </Col>
            <Col sm={6} className="d-flex align-items-center">
              <a href="#" className={styles.seeAllLink} onClick={updateorderBy}>
                投稿日が新しい順
                <span className="material-symbols-outlined">
                  {orderByOption == "desc" ? "expand_more" : "expand_less"}
                </span>
              </a>
            </Col>
          </Row>
        </Container>
      )}

      {width <= 576 && (
        <>
          <div className={`${styles.gridListmobileOptions} my-3`}>
            <p>
              全{countSummary.total.toLocaleString()}0件中　{countSummary.start}{" "}
              - {countSummary.end}件目を表示
            </p>
            <p>表示切替</p>
            <div>
              <ViewStreamSharpIcon
                className={styles.icon}
                onClick={() => {
                  setShowAnimalCardGrid(false);
                }}
              />
              <GridViewSharpIcon
                className={styles.icon}
                onClick={() => {
                  setShowAnimalCardGrid(true);
                }}
              />
            </div>
          </div>
          <Container>
            <Row>
              <Col sm={12} className="d-flex align-items-center">
                <a
                  href="#"
                  className={styles.seeAllLink}
                  onClick={updateorderBy}>
                  投稿日が新しい順
                  <span className="material-symbols-outlined">
                    {orderByOption == "desc" ? "expand_more" : "expand_less"}
                  </span>
                </a>
              </Col>
            </Row>
          </Container>
        </>
      )}

      <Container>
        <div sm={12}>
          <div
            ref={animalItemContainerRef}
            className={`w-100 ${
              width > 576 && !showAnimalCardGrid
                ? styles.animalItemRow
                : styles.itemCol
            }`}>
            {animalList.map((item, index) => {
              if (width <= 576 && showAnimalCardGrid) {
                return (
                  <div key={index} className={styles.width50}>
                    <div
                      className={`${styles.animalItemCard}  ${styles.animalItemCardGrid} my-1`}>
                      <AnimalItem
                        animalItemContainerRef={animalItemContainerRef}
                        data={item}
                        type="longcard"
                        show="grid"
                      />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={index}
                    className={`${styles.animalItemCard} mt-3 mt-sm-2`}>
                    <AnimalItem
                      animalItemContainerRef={animalItemContainerRef}
                      data={item}
                      type="card"
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>

        {((width <= 576 && countSummary.total > 3) ||
          (width > 576 && countSummary.total > 9)) && (
          <div className="d-flex justify-content-center my-1 my-sm-3">
            <button
              className={`${styles.loadMoreReviewButton} ${styles.buttonYellow}`}>
              すべて見る
              <KeyboardArrowRightSharpIcon />
            </button>
          </div>
        )}
      </Container>
    </>
  );
};
