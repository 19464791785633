import _ from "lodash";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Button, Col, Container, Form, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight, faPaw } from "@fortawesome/free-solid-svg-icons";

import PetDetailsShort from "../Pet/PetDetailsShort";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ProgressStep from "../../components/progressStep/ProgressStep";

import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import "react-time-picker/dist/TimePicker.css";
import { useSendEmail } from "../../hooks/useSendMail";
import { dog_breed } from "../../utils/CatDogData";
import { getName } from "../../utils/Utils";
import BreederSortDetailsInquery from "../../components/Home/BreederSortDetailsInquery";

export default function InquiryCheck() {
  const inquiryData = JSON.parse(localStorage.getItem("inquiryData")) || false;
  const petDatils = JSON.parse(localStorage.getItem("petDatils")) || false;
  const breederDatils =
    JSON.parse(localStorage.getItem("breederDatils")) || false;

  const navigate = useNavigate();
  const { sendMail } = useSendEmail();

  const { width } = useWindowDimensions();

  const inquiryHandler = async () => {
    sendMail({
      mailOption: {
        from: "【LOOKFOR】 <info@microchip-registration.com>",
        to: breederDatils.email,
        // cc: breederDatils.email,
        subject: "【LOOKFOR】見学希望日についての確認",
        text: ``,
        html: `
        いつもLOOKFORをご利用いただき、誠にありがとうございます。<br/>
        見学希望・お問い合わせに関して以下の情報で承りました。<br/>
        <br/>
        【見学希望日時】<br/>
        　•	第1希望日: ${`${moment(inquiryData.visit_date_first_choice).format(
          "YYYY年MM月DD日"
        )} ${inquiryData.visit_time_first_choice}`}<br/>
        　•	第2希望日: ${`${moment(inquiryData.visit_date_second_choice).format(
          "YYYY年MM月DD日"
        )} ${inquiryData.visit_time_second_choice}`}<br/>

        <br/>
        【お問い合せ内容】<br/>
        　　${inquiryData.inquiry_details}
        <br/>
        ${breederDatils.contactQuestions
          .map((question, index) => {
            return `
            <p style:"margin-bottom: 0">　　${question.sectionTitle}</p>
            <p style:"margin-bottom: 0">
              ${question.checkboxLabels
                .map((label, labelIndex) => {
                  const isChecked =
                    inquiryData.questions &&
                    inquiryData.questions[index] &&
                    inquiryData.questions[index].checkboxLabels[labelIndex]
                      .checked
                      ? "○"
                      : "×";
                  const labelText =
                    inquiryData.questions &&
                    inquiryData.questions[index] &&
                    inquiryData.questions[index].checkboxLabels[labelIndex]
                      .label;

                  return `　　　<span>${isChecked} ${labelText} <br/></span>`;
                })
                .join("")}
            </p>
          `;
          })
          .join("")}
        <br/>
        【ワンちゃん情報】<br/>
        　•	犬種: ${
          petDatils.mcData.animal_breed !== "99"
            ? _.find(dog_breed, ["value", petDatils.mcData.animal_breed])?.label
            : petDatils.mcData.animal_breed_other
        }<br/>
        　•	誕生日: ${moment(petDatils.mcData.animal_birthDay).format(
          "YYYY年MM月DD日"
        )}<br/>
        　•	価格: ${
          petDatils.sellPlatformPrice
            ? `${parseInt(
                petDatils.sellPlatformPrice
              ).toLocaleString()}円 (税込)`
            : ""
        }<br/>
          <br/>
        【ブリーダー情報】<br/>
        　•	名前: ${getName(breederDatils)}<br/>
        　•	見学住所: ${petDatils.sellPlatform_prefecture} ${
          petDatils.sellPlatform_addressLine1
        }<br/>
        　•	動物取り扱い: ${
          breederDatils.animal_handler_business_type1_slot1
        }<br/>
          <br/>
        【注意事項】<br/>
        ・販売者とのスムースなやり取りをお願いします <br />
        販売者とスムースなやり取りが困難な場合や販売者とトラブルになった場合は、詳細調査の上、当サイトのご利用を制限させていただく場合があります。 <br />
        ・短期間に複数の販売者への問合せはお控えください <br />
        子犬のお迎えするか見送るかの返事を必ず行ってから、新しい販売者へお問い合わせを行ってください。 <br />
        ・お迎えの優先順について <br />
        子犬のお迎え希望が重なった場合、見学申込順ではなく、購入予約順で決まる場合もあります。販売者に直接お問い合わせください。 <br />
        <br/>
        
        ※このメールは送信専用のメールアドレスから配信されています。<br />
        　ご返信いただいてもお答えできませんのでご了承ください。<br />

        `
      }
    });

    navigate("/inquirycomplete");
  };
  return (
    <>
      <div className="cmn-bg">
        <div className="cmn-pastel-yellow-bg">
          <Container>
            <p className="yollow-title fs-5 fw-bold cmn-text-color py-3 text-center text-sm-start">
              <FontAwesomeIcon icon={faPaw} /> 見学希望・お問い合わせフォーム
            </p>
          </Container>
        </div>
        <Container>
          <Row className="justify-content-center mt-5 mb-5">
            <ProgressStep
              props={[
                { value: "内容入力", link: "#" },
                { value: "内容確認", link: "#" },
                { value: "送信完了", link: "#" }
              ]}
              activeNo={2}
            />
          </Row>
          <Row className="justify-content-center cmn-text-color-ash">
            <Col lg={11} className={`${width < 576 ? `p-0` : ``}`}>
              <div className="py-sm-4 cmn-pastel-yellow-bg">
                <Row className="mx-sm-4">
                  <Col
                    lg={3}
                    className={`${
                      width < 576 ? `cmn-pastel-yellow-bg py-2 px-5` : ``
                    } ${
                      width >= 576
                        ? `bg-white border-2 border-bottom  border-warning p-4`
                        : ``
                    } fw-bold cmn-text-color`}>
                    見学希望日時{"　"}
                    <Badge bg="dark" text="white" className="rounded-0">
                      必須
                    </Badge>
                  </Col>
                  <Col
                    lg={9}
                    className={`bg-white  ${width < 576 && ` py-4 px-5`} ${
                      width >= 576 &&
                      `border-2 border-start border-bottom  border-warning p-4`
                    }  cmn-text-color-ash`}>
                    <Row className="justify-content-center mb-3">
                      <Col xs={12}>
                        【第1希望】
                        {`${moment(inquiryData.visit_date_first_choice).format(
                          "YYYY年MM月DD日"
                        )} ${inquiryData.visit_time_first_choice}`}
                      </Col>

                      <Col xs={12}>
                        【第2希望】
                        {`${moment(inquiryData.visit_date_second_choice).format(
                          "YYYY年MM月DD日"
                        )} ${inquiryData.visit_time_second_choice}`}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="mx-sm-4">
                  <Col
                    lg={3}
                    className={`${
                      width < 576 ? `cmn-pastel-yellow-bg py-2 px-5` : ``
                    } ${
                      width >= 576
                        ? `bg-white border-2  border-warning p-4`
                        : ``
                    } fw-bold cmn-text-color`}>
                    お問い合わせ内容{"　"}
                    <Badge bg="dark" text="white" className="rounded-0">
                      必須
                    </Badge>
                  </Col>
                  <Col
                    lg={9}
                    className={`bg-white  ${width < 576 && ` py-4 px-5`} ${
                      width >= 576 &&
                      `border-2 border-start  border-warning p-4`
                    }  cmn-text-color-ash`}>
                    <>
                      <div className="pb-3">{inquiryData.inquiry_details}</div>
                    </>
                  </Col>
                </Row>
                {breederDatils && breederDatils.contactQuestions && (
                  <>
                    <Row className="mx-sm-4">
                      <p
                        className={`px-3 py-2 m-0 w-100 cmn-text-color text-center text-sm-start ${
                          width < 576 ? `px-4 py-0 fw-bold  bg-white` : ``
                        }`}>
                        子犬を飼育できる環境のお客様のみ販売者へお問い合わせを行うことができます。
                      </p>
                    </Row>

                    {breederDatils.contactQuestions.map((question, index) => (
                      <>
                        <Row className="mx-sm-4">
                          <Col
                            lg={3}
                            className={`${
                              width < 576
                                ? `cmn-pastel-yellow-bg py-2 px-5`
                                : ``
                            } ${
                              width >= 576
                                ? `bg-white border-2 ${
                                    breederDatils.contactQuestions.length -
                                      1 !==
                                    index
                                      ? `border-bottom`
                                      : ``
                                  }    border-warning p-4`
                                : ``
                            } fw-bold cmn-text-color`}>
                            {question.sectionTitle}
                          </Col>
                          <Col
                            lg={9}
                            className={`bg-white  ${width < 576 && ` py-4 px-5`} ${
                              width >= 576 &&
                              `border-2 border-start ${
                                breederDatils.contactQuestions.length - 1 !==
                                index
                                  ? `border-bottom`
                                  : ``
                              }  border-warning p-4`
                            }  cmn-text-color-ash`}>
                            <>
                              <div>
                                {question.checkboxLabels.map(
                                  (label, labelIndex) => {
                                    return (
                                      <Form.Check
                                        type={"checkbox"}
                                        label={label}
                                        name={`questions[${index}].checkboxLabels[${labelIndex}].checked`}
                                        checked={
                                          inquiryData.questions &&
                                          inquiryData.questions[index] &&
                                          inquiryData.questions[index]
                                            .checkboxLabels[labelIndex].checked
                                        }
                                      />
                                    );
                                  }
                                )}
                              </div>
                            </>
                          </Col>
                        </Row>
                      </>
                    ))}
                  </>
                )}
              </div>

              <Row>
                <Col className={`${width < 576 ? `p-4` : ``}`}>
                  <p className="fs-4 fw-bold cmn-text-color mt-5">
                    <span className="border-5 border-start border-dark ps-2 cmn-text-color">
                      対象のワンちゃん情報
                    </span>
                  </p>
                  <Container className="bg-white p-4 rounded-3">
                    <PetDetailsShort isPending={false} petDatils={petDatils} />
                  </Container>

                  <p className="fs-4 fw-bold cmn-text-color mt-5">
                    <span className="border-5 border-start border-dark ps-2 cmn-text-color">
                      ブリーダー情報
                    </span>
                  </p>
                  <Container className="bg-white p-4 rounded-3">
                    <BreederSortDetailsInquery
                      isPending={false}
                      breeder={breederDatils}
                      petDatils={petDatils}
                    />
                  </Container>

                  <Container className="cmn-ash-lite-bg mt-5 mb-4 p-4">
                    <p className="fw-bold b-2">
                      お問い合わせにあたっての注意事項
                    </p>
                    <small>
                      <ul>
                        <li className="mb-3">
                          <span className="fw-bold">
                            販売者とのスムースなやり取りをお願いします{" "}
                          </span>
                          <br />
                          販売者とスムースなやり取りが困難な場合や販売者とトラブルになった場合は、詳細調査の上、当サイトのご利用を制限させていただく場合があります。
                        </li>
                        <li className="mb-3">
                          <span className="fw-bold">
                            短期間に複数の販売者への問合せはお控えください
                          </span>
                          <br />
                          子犬のお迎えするか見送るかの返事を必ず行ってから、新しい販売者へお問い合わせを行ってください。
                        </li>
                        <li className="mb-3">
                          <span className="fw-bold">
                            お迎えの優先順について{" "}
                          </span>
                          <br />
                          子犬のお迎え希望が重なった場合、見学申込順ではなく、購入予約順で決まる場合もあります。販売者に直接お問い合わせください。
                        </li>
                      </ul>
                    </small>
                  </Container>
                </Col>
              </Row>
              <small>
                <p className="text-center  fs-6 px-4">
                  お問い合わせを行うには、
                  <Link className="text-reset text-nowrap">利用規約</Link>・
                  <Link className="text-reset text-nowrap">会員規約</Link>・
                  <Link className="text-reset text-nowrap">
                    個人情報保護方針（プライバシーポリシー）
                  </Link>
                  への同意が必要になります。
                </p>
              </small>

              <Row className="justify-content-center pb-4">
                <div className="text-center mt-3">
                  <Button
                    className="cmn-yollow-btn mb-2 py-4"
                    type="submit"
                    onClick={inquiryHandler}>
                    送信
                  </Button>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
