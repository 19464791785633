import React, { useEffect, useState } from "react";
import styles from "./MobileSideBar.module.css";
import { useAuthContext } from "../../../hooks/useAuthContext";
import LoginModal from "../../login/LoginModal";
import { useLogout } from "../../../hooks/useLogout";
import { FavoriteBorderOutlined, HistoryOutlined } from "@mui/icons-material";
import MobileSidebarSecondLevelItem from "./MobileSidebarSecondLevelItem";
import { Link, useNavigate } from "react-router-dom";
import { useCollection } from "../../../hooks/useCollection";
import { dog_breed } from "../../../utils/CatDogData";
import LocationSelectModal from "./LocationSelectModal";
import { getName } from "../../../utils/Utils";

const MobileSideBar = ({ showMobileMenu, setShowMobileMenu }) => {
  const [mobileSideBar, setMobileSideBar] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { user } = useAuthContext();
  const [showModal, setShowModal] = useState(false);
  const [BreederList, setBreederList] = useState([]);
  const navigate = useNavigate();
  const { documents: PetList } = useCollection(
    "microchip",
    ["sellPlatform", "==", true],
    ""
  );

  const { documents: Breeders } = useCollection(
    "users",
    ["group", "==", "breederpet"],
    "transferMNY",
    "desc"
  );

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = (e) => {
    setShowModal(true);
  };

  const getSelectedLocationItems = (itemsWithParent, items) => {
    const queryParams = new URLSearchParams();
    queryParams.append("location", items);
    navigate(`/search/?${queryParams.toString()}`);
  };
  const [popularDogBreed, setPopularDogBreed] = useState([]);

  useEffect(() => {
    if (PetList) {
      let updatedDogBreed = dog_breed.map((breed) => {
        const count = PetList.filter(
          (pet) => pet.animal_breed === breed.value
        ).length;
        return { ...breed, count };
      });
      updatedDogBreed.sort((a, b) => b.count - a.count);
      updatedDogBreed = updatedDogBreed.map((item) => {
        let data = item;
        data.img = "/assets/img/PopularDogDummy.png";
        data.type = "img-link";
        data.name = data.label;
        data.url = "/breed/" + data.value;
        return data;
      });
      setPopularDogBreed(updatedDogBreed);
    }
  }, [PetList]);

  useEffect(() => {
    if (Breeders) {
      // console.log(Breeders);
      let data = Breeders.map((item) => {
        // console.log(item);
        let breeder = {};
        breeder.type = "img-link";
        breeder.name = getName(item);
        breeder.img = item?.img || "/assets/img/PopularBreederDog.png";
        breeder.url = "/breeder/" + item.lookforusername;
        return breeder;
      });
      setBreederList(data);

      // console.log(data);
    }
  }, [Breeders]);

  const { logout } = useLogout();
  useEffect(() => {
    if (showMobileMenu == true) {
      setMobileSideBar(true);
    } else {
      const timerId = setTimeout(() => {
        setMobileSideBar(false);
      }, 500);
      return () => clearTimeout(timerId);
    }
  }, [showMobileMenu]);

  return (
    <>
      {mobileSideBar && (
        <div
          className={`${
            showMobileMenu ? styles.showMobileMenu : styles.hideMobileMenu
          } ${styles.mobileMenu}`}
        >
          <div className={styles.group}>
            <div className={styles.title}>横スライド</div>
            <div className={styles.authConten}>
              {!user && (
                <>
                  <a
                    href="#"
                    className={styles.loginButton}
                    onClick={() => {
                      setShowLoginModal(true);
                    }}
                  >
                    ログイン
                  </a>
                  <a
                    href={`${process.env.REACT_APP_MCR_URL}signup?group=owner&returnurl=${process.env.REACT_APP_KOINU_URL}&returnsite=${process.env.REACT_APP_KOINU_TITLE}`}
                    className={styles.regButton}
                  >
                    新規登録
                  </a>
                </>
              )}
              {user && (
                <div className={styles.loggedInItems}>
                  <a href="#" className={styles.loggedInItem}>
                    <FavoriteBorderOutlined />
                    <p>お気に入り</p>
                  </a>
                  <a href="#" className={styles.loggedInItem}>
                    <HistoryOutlined />
                    <p>閲覧履歴</p>
                  </a>
                  <a href="#" className={styles.loggedInItem}>
                    <span className="material-symbols-outlined">
                      quick_reference_all
                    </span>
                    <p>検索条件</p>
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className={styles.group}>
            <div className={styles.title}>ワンちゃんを探す</div>
            <Link
              to="/#filter=find-puppy"
              onClick={() => {
                setShowMobileMenu(false);
              }}
              className={styles.LinkedItem}
            >
              子犬を探す
            </Link>
            <Link
              to="/#filter=find-adult-dog"
              onClick={() => {
                setShowMobileMenu(false);
              }}
              className={styles.LinkedItem}
            >
              里親になる
            </Link>
            <MobileSidebarSecondLevelItem
              name={"人気の犬種から探す"}
              items={popularDogBreed}
            />
            <MobileSidebarSecondLevelItem
              clickData={handleModalShow}
              name={"都道府県から探す"}
              items={[]}
            />

            <Link to="/search" className={styles.LinkedItem}>
              全ての検索条件履歴
              <span className="material-symbols-outlined">navigate_next</span>
            </Link>
          </div>

          <div className={styles.group}>
            <div className={styles.title}>ブリーダーを探す</div>
            <MobileSidebarSecondLevelItem
              name={"人気のブリーダーから探す"}
              items={BreederList}
            />

            <Link
              to="#filter=find-breeder"
              onClick={() => {
                setShowMobileMenu(false);
              }}
              className={styles.LinkedItem}
            >
              都道府県から探す
              <span className="material-symbols-outlined">navigate_next</span>
            </Link>
          </div>

          <div className={styles.group}>
            <div className={styles.title}>お客様サポート</div>
            <a href="#" className={styles.LinkedItem}>
              よくある質問
            </a>
            <a href="#" className={styles.LinkedItem}>
              お問い合わせ
            </a>
          </div>
        </div>
      )}

      <LoginModal
        show={showLoginModal && !user}
        onHide={() => {
          setShowLoginModal(false);
        }}
        modalShowController={() => {
          setShowLoginModal(false);
        }}
      />

      <LocationSelectModal
        showModal={showModal}
        handleModalClose={handleModalClose}
        getSelectedLocationItems={getSelectedLocationItems}
      />
    </>
  );
};

export default MobileSideBar;
