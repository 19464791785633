import React, { useEffect, useState } from "react";
import styles from "./PopularDogBreed.module.css";
import { dog_breed } from "../../../utils/CatDogData";
import { useCollection } from "../../../hooks/useCollection";
import { Link } from "react-router-dom";
import { Placeholder } from "react-bootstrap";

const PopularDogBreed = () => {
  const { documents: PetList } = useCollection("microchip", [
    "sellPlatform",
    "==",
    true
  ]);
  const [rankedBreed, setRankedBreed] = useState([]);
  useEffect(() => {
    // Check if PetList is not null or undefined
    if (PetList) {
      // Update the count in dogBreed based on PetList's "animal_breed" field
      let updatedDogBreed = dog_breed.map((breed) => {
        const count = PetList.filter(
          (pet) => pet.animal_breed === breed.value
        ).length;
        return { ...breed, count };
      });
      updatedDogBreed.sort((a, b) => b.count - a.count);
      updatedDogBreed = updatedDogBreed.slice(0, 30);
      setRankedBreed(updatedDogBreed);
    }
  }, [PetList]);
  return (
    <div className="mt-2">
      <p className={styles.PopularDogBreed}>人気の犬種一覧</p>
      {!rankedBreed && (
        <>
          <Placeholder animation="glow">
            {Array.from({ length: 30 }, (_, i) => (
              <Placeholder
                key={i}
                className="w-100 my-1"
                style={{ height: "20px" }}
              />
            ))}
          </Placeholder>
        </>
      )}

      {rankedBreed && (
        <>
          {rankedBreed.map((item, i) => {
            return (
              <div key={i} className="my-2">
                {/* <a className={styles.link} href={item.link}>
                {item.name} ({item.count.toLocaleString()})
              </a> */}

                <Link className={styles.link} to={"/breed/" + item.value}>
                  {item.label} ({item.count.toLocaleString()})
                </Link>
              </div>
            );
          })}
        </>
      )}

      <div className="mt-3 w-100">
        <Link className={styles.viewAll} to={"/all-breed/"}>
          ランキングをすべて見る
        </Link>
      </div>
    </div>
  );
};

export default PopularDogBreed;
