import React, { useEffect, useState } from "react";
import Style from "./Static.module.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CustomBreadcrumb from "../../components/Universal/CustomBreadcrumb";
import { Col, Container, Row } from "react-bootstrap";

const TermsAndCondition = () => {
  let [breadcrumb, setBreadcrumb] = useState(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    let breadcrumbItem = [];
    breadcrumbItem.push({ name: "LOOKFOR ", link: "/" });
    breadcrumbItem.push({ name: "LOOKFOR利用規約 " });
    setBreadcrumb(breadcrumbItem);
  }, []);
  return (
    <>
      {width > 576 && (
        <>
          <CustomBreadcrumb Breadcrumb={breadcrumb} />
        </>
      )}
      <div className={Style.TermsAndCondition}>
        <Container className="mb-sm-5">
          <Row>
            <Col sm={12}>
              <h5 className={Style.title}>LOOKFOR利用規約</h5>
            </Col>
          </Row>

          <div className={`my-sm-4 w-100  ${Style.termsContainer}`}>
            <p>
              株式会社DETECT（以下「当社」といいます）は、当社が運営する犬猫譲渡サイト「LOOKFOR」の運営・管理を通じてご提供するサービス（以下、「本サービス」といいます）の利用規約（以下「本規約」といいます）を以下の通り定めます。
              <br></br>
              本サービスをご利用される方は、本規約の全ての条項を承諾していただくことが条件となりますので、ご登録される前に本規約を必ずお読みください。
            </p>

            <p>
              第1条 目的<br></br>
              1.会員(第2条第1項で定義します)の所有もしく販売する犬および猫を飼い主に譲渡するために本サービスを利用できます。
              <br></br>
              2.全ての会員は、子犬および子猫の販売（里親は含まれません）に際し、環境省のマイクロチップ登録による飼い主へ譲渡後、1年間以内に先天性障害が発症した場合、飼い主に対して瑕疵保証（契約不適合）の責務を負います。（ただし、販売金額を超えての責任は負いません）
            </p>

            <p>
              第2条 会員登録 1.
              「会員」とは、本規約の内容全てを承認いただいた上、当社所定の手続きに従い会員登録を申請し、当社がこれを承認したものをいいます。
              <br></br>
              2.会員登録手続は、本サービスの会員登録ページから当社の指定する方法に従い、会員登録を希望する本人が行うものとします。代理による登録は一切認められません。
              <br></br>
              3.
              本サイトは、株式会社PETNEXT社の運用管理するマイクロチップ登録サイト（以下「MCR」という。）とデータを共有して、会員の登録業務の利便性を図ります。
              <br></br>
              4.
              会員は、本サイトでの利用料および紹介料などの支払いの義務は発生しないものの、マイクロチップ登録およびそれに付随する費用（マイクロチップおよび先天性障害保証などの保証料、行政書士による代行登録費用、電子販売契約書利用料および「MCR」月額管理費など）を負担する前提で本サイトに登録していただきます。
              <br></br>
              5.
              当社は、会員登録を申請した者が以下の各号のいずれかの事由に該当する場合は、登録を拒否し、または事前通知することなく一旦なされた登録を取り消すことがあります。
              <br></br>
              •当社の提供する何らかのサービスに関する規約に違反をしたことなどにより会員登録の抹消等
              <br></br>
              •会員登録の申請に虚偽の事項が含まれている場合<br></br>
              •商品又はサービス（以下「商品等」といいます）に関する料金などの支払債務の履行遅延その他
              <br></br>
              •本規約第6条（禁止事項）の行為を行った場合<br></br>
              •その他本規約に違反した場合
            </p>

            <p>
              第3条 登録内容の変更<br></br>
              1.登録情報の内容の全部又は一部に関して変更が生じた場合は、会員は直ちに当社が別途指定する方法により登録内容を変更するものとします。
              <br></br>
              2.当該通知を怠った場合には、既に登録済みの情報に基づく処理を適正・有効なものとします。
              <br></br>
              3.また、変更登録がなかったことにより生じた損害について、当社は一切責任を負いません。
            </p>

            <p>
              第4条 ユーザIDおよびパスワードの管理<br></br>
              1.会員は、会員登録等の際に会員本人が設定し、承認・登録されたユーザID及びパスワードの利用、管理について一切の責任を負うものとします。
              <br></br>
              2.会員は、ユーザID及びパスワードを第三者へ譲渡、承継、名義変更、貸与、開示又は漏洩してはならないものとします。
              <br></br>
              3.会員のユーザID及びパスワード又はクレジットカード番号の使用・管理上の問題又は第三者による不正使用等に起因する損害について当社は一切責任を負わないものとします。
            </p>

            <p>
              第5条 会員の退会<br></br>
              1.会員が退会を希望する場合には、会員本人が会員情報の削除を行ってください。当社所定の退会手続の終了に伴い、退会となります。
            </p>

            <p>
              第6条 禁止事項<br></br>
              本サービスの利用に際して、会員は以下の各号の行為を行ってはならないものとします。
              <br></br>
              1.本サービスの利用に際して、会員は以下の各号の行為を行ってはならないものとします。
              <br></br>
              2.会員登録又は登録内容の変更の際に虚偽の内容、又は第三者の情報を入力する行為
              <br></br>
              3.本サービスの運営を妨げ、その他本サービスに支障をきたすおそれのある行為
              <br></br>
              4.他の会員および第三者もしくは当社の財産、プライバシー、著作権等の知的財産、その他の権利を侵害する行為、又はそれらのおそれのある行為
              <br></br>
              5.他の会員および第三者、もしくは当社の誹謗、中傷、名誉を毀損する行為、又はそれらの恐れのある行為
              <br></br>
              6.他の会員および第三者、もしくは当社に迷惑、不利益もしくは損害を与える行為、又はそれらの恐れのある行為
              <br></br>
              7.ユーザID及びパスワードを不正に使用する行為<br></br>
              8.同業者の再販など、営利目的での購入<br></br>
              9.その他弊社が不適当と判断する行為
            </p>

            <p>
              第7条 著作権、商標権およびその他の知的所有権
              1.本サービスを通じて提供されるいかなるコンテンツ(文章、イラスト、デザイン、写真、画像、ロゴ、アイコン、映像、プログラム等）（以下「コンテンツ」といいます）の著作権、商標権およびその他の知的所有権は全て当社に帰属するものであり、会員および利用者はこれらの権利を侵害する行為を行わないものとします。
              <br></br>
              2.目的の如何を問わず、本サービスのコンテンツその他掲載内容全て、または一部を許可なく使用（複製、改変、転用、転送、配布、掲示、販売、出版など）する行為は固く禁止します。
              <br></br>
              3.本条の規定に違反して第三者との間で問題が生じた場合、会員は自己の責任と費用においてかかる問題を解決するとともに当社に何等の損害、損失又は不利益等を与えないものとします。
            </p>

            <p>
              第8条 会員の義務<br></br>
              1.会員が本サイトで販売した子犬・子猫（マイクロチップ登録証明書付き）について、別表に定める先天性障害を、販売日より１年間の販売金額を限度に、飼い主に保証していただきます
              <br></br>
              2.会員から飼い主への販売（譲渡）を明確にするため、会員は、販売日に飼い主への所有権変更を必ず「ＭＣＲ」サイトにて行うものとします。
              <br></br>
              3.前２項を実施しなかった場合は、互助会の制度を利用していた場合でも先天性障害に係る瑕疵保証（契約不適合）を互助会ではお支払できません。
            </p>

            <p>
              第9条 情報の管理<br></br>
              1.当社は取得した会員情報を下記の目的に利用するものとします。
              <br></br>
              また、その利用目的達成のために当社が選定した業務委託会社へ委託する場合があります。
              <br></br>
              •本サービス会員、ユーザの管理<br></br>
              •メールマガジンの発行<br></br>
              •当社商品等の紹介、広告、宣伝、販売の勧誘<br></br>
              •商品の発送<br></br>
              •カスタマーサポート<br></br>
              •キャンペーン等企画、アンケートの実施<br></br>
              •その他本サービスに関連したコンテンツをご提供するため<br></br>
              •個人を特定できない状態に加工した後のマーケティング活動<br></br>
              2.当社は、原則として会員が会員登録手続および、その後の会員情報の変更登録において、開示した会員情報を会員の事前の同意なく第三者に対して開示・提供することはありません。ただし、以下の場合には、会員の事前の同意なく、当社はこれらの会員情報を開示・提供できるものとします。
              <br></br>
              •統計的なデータとして、会員を識別できない状態に加工した場合
              <br></br>
              •法令などに基づき、会員情報の開示・提供を求められた場合<br></br>
              3.当社は、当社が利用することを承諾した上で会員が発信したコメントその他の情報について、当該情報が他の会員、第三者、もしくは当社の名誉もしくは信用を明らかに毀損、または法令に違反していると認められた場合等の理由により、当社が必要と判断した場合には会員に断りなくこれを削除、もしくは掲載場所を変更することができるものとします。
              <br></br>
              4.前3項の他、当社は別途定める「個人情報の取り扱いについて」に則り取得した会員情報を取り扱うものとします。
            </p>

            <p>
              第10条 サービスの利用<br></br>
              本サービスを利用するに際しては、本規約及び当社が別途定める「個人情報の取り扱いについて」に、あらかじめ同意いただくものとします。
            </p>

            <p>
              第11条 サービスの変更・廃止<br></br>
              1.本サービスを提供するためのコンピュータシステム(以下「システム」といいます)のトラブル等で緊急な保守点検が必要なとき
              <br></br>
              2.火災、停電、天災その他不可抗力によりシステムの運用が困難になった場合
              <br></br>
              3.人為的災害（戦争､暴動､騒乱､労働争議等）によりシステムの運用が困難になった場合
              <br></br>
              4.第三者による妨害行為等により、システムの運用が困難になった場合
              <br></br>
              5.その他、やむを得ずシステムの停止が必要と当社が判断した場合
            </p>

            <p>
              第12条 その他の免責事項<br></br>
              1.当社は、利用者の登録内容に従って事務を処理することにより会員に生じたいかなる損害についても、免責されるものとします。
              <br></br>
              2.当社が相当の安全策を講じたにもかかわらず通信回線やコンピュータなどに障害が生じ、システムの中断・遅滞・中止等による損害、ウェブページが改ざんされたことにより会員に生じた損害については、当社は一切責任を負いません。
              <br></br>
              3.当社のウェブページ・サーバ・ドメインなどから送られるメール・コンテンツに、コンピューター・ウィルスなどの有害なものが含まれていないことを保証いたしません。
              <br></br>
              4.当社は、会員に対し、適宜情報提供やアドバイスを行うことがありますが、それにより責任を負うものではありません。
              <br></br>
              5.当社は、会員が本規約に違反したことによって生じた損害等については、一切責任を負わないものとします。
              <br></br>
              6.会員が、本サービスをご利用になることにより、他の会員または第三者に対して損害等を与えた場合には、当該会員は自己の責任と費用において解決し、当社には一切損害等を与えないものとします。
            </p>

            <p>
              第13条 準拠法、合意管轄<br></br>
              本規約は日本法に基づき解釈されるものとし、本規約に関し訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所といたします。
              <br></br>
            </p>
          </div>
        </Container>

        <div className="w-100">
          <CustomBreadcrumb position={"bottom"} Breadcrumb={breadcrumb} />
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;
