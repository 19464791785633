import { db, firebaseErrors } from "../firebase/config";

//firebase imports
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useAddDocs = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const addADoc = async (c, docs) => {
    let ref = collection(db, c);
    let ID = await addDoc(ref, { ...docs, createdAt: serverTimestamp() }).then(
      (res) => {
        if (!isCanceled) {
          setIsPending(false);
          setIsConfirmed(true);
        }
        return res.id;
      },
      (err) => {
        if (!isCanceled) {
          setIsPending(false);
          setIsConfirmed(false);
          setError(firebaseErrors[err.code] || err.message);
        }
        return false;
      }
    );
    // console.log("useAddDocs", ID)
    return ID;
  };

  useEffect(() => {
    return () => setIsCanceled(true);
  }, []);
  return { addADoc, isPending, isConfirmed, error };
};
