import styles from "./Header.module.css";

import React, { useEffect, useRef, useState } from "react";

import { useAuthContext } from "../../../hooks/useAuthContext";
import { useLogout } from "../../../hooks/useLogout";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import LoginModal from "../../login/LoginModal";
import SecondHeader from "./SecondHeader";
import FixedHeader from "./FixedHeader";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { SearchOutlined } from "@mui/icons-material";
import TotalCountComponent from "./TotalCountComponent";
import MobileSideBar from "./MobileSideBar";

export default function Header() {
  const { user } = useAuthContext();
  const { logout } = useLogout();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [navExpanded, setNavExpanded] = useState(false);
  const [showFloatingHeader, setShowFloatingHeader] = useState(false);
  const { width } = useWindowDimensions();
  const [showMobileBar, setShowMobileBar] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [willHideMobileMenu, setWillHideMobileMenu] = useState(false);

  const mobileMenu = (e) => {
    setShowMobileMenu(!showMobileMenu);
  };

  const setNavExpand = () => {
    setNavExpanded(!navExpanded);
  };

  const closeNav = () => {
    setNavExpanded(false);
  };

  const { pathname } = useLocation();
  const showTopBar = (e) => {
    e.preventDefault();
    setShowMobileBar(!showMobileBar);
  };

  useEffect(() => {
    // setBg(true);
    // setShowMenu(false);
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant" // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const windowScrollTop = window.scrollY;
      if (windowScrollTop >= 140) {
        setShowFloatingHeader(true);
      } else {
        setShowFloatingHeader(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showMobileMenu == false) {
      const timerId = setTimeout(() => {
        setWillHideMobileMenu(true);
      }, 300);
      return () => clearTimeout(timerId);
    } else {
      setWillHideMobileMenu(false);
    }
  }, [showMobileMenu]);

  return (
    <>
      {/* This is needed for add some spaces before content as header is fixed header */}
      <div
        className={`${width <= 576 ? ` ${styles.mobileHeaderSpace}` : ""}`}
      ></div>
      <Navbar
        expand="lg"
        onToggle={setNavExpand}
        className={[styles.bgBlue, styles.navHeight]}
        expanded={navExpanded}
      >
        <Container className="position-relative justify-content-start">
          <Link to="/" className="text-decoration-none "> 
          <Navbar.Brand className={`${styles.title} `} >
            LOOKFOR
          </Navbar.Brand>
          </Link>

          {width > 576 && (
            <>
              <div className="d-flex justify-content-center align-items-end flex-column ms-auto">
                <TotalCountComponent />

                <div className={`justify-content-end ${styles.linkContainer}`}>
                  {!user && (
                    <>
                      <a
                        href="#"
                        className={styles.authText}
                        onClick={() => {
                          setShowLoginModal(true);
                        }}
                      >
                        ログイン
                      </a>

                      <a
                        href={`${process.env.REACT_APP_MCR_URL}signup?group=owner&returnurl=${process.env.REACT_APP_KOINU_URL}&returnsite=${process.env.REACT_APP_KOINU_TITLE}`}
                        className={`${styles.navButton} ms-0 ms-sm-1 mt-2 mt-sm-0 px-3`}
                      >
                        新規登録
                      </a>
                    </>
                  )}

                  {user && (
                    <a
                      className={styles.authText}
                      onClick={() => {
                        setShowLoginModal(false);
                        closeNav();
                        logout();
                      }}
                    >
                      ログアウト
                    </a>
                  )}
                </div>
              </div>
            </>
          )}

          {width <= 576 && (
            <>
              <Navbar.Toggle
                className={`ms-auto ${styles.navToggleButton} ${
                  !showMobileMenu
                    ? `${
                        willHideMobileMenu
                          ? styles.showHamberToggle
                          : styles.willHideMenu
                      }`
                    : styles.showCrossToggle
                }`}
                onClick={mobileMenu}
              />
            </>
          )}
        </Container>
      </Navbar>
      <LoginModal
        show={showLoginModal && !user}
        onHide={() => {
          setShowLoginModal(false);
        }}
        modalShowController={() => {
          setShowLoginModal(false);
        }}
      />
      {width > 576 && (
        <>
          <SecondHeader />
          {showFloatingHeader && <FixedHeader />}
        </>
      )}
      {width <= 576 && (
        <>
          <SecondHeader />
          <MobileSideBar
            showMobileMenu={showMobileMenu}
            setShowMobileMenu={setShowMobileMenu}
          />
        </>
      )}
    </>
  );
}
