import { useEffect, useRef, useState } from "react";
import { db } from "../firebase/config";

//firebase imports
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where
} from "firebase/firestore";

export const useCollection = (
  c,
  _q,
  o = null,
  ob = "desc",
  dataLimit = null
) => {
  const [documents, setDocuments] = useState(null);
  //set the query
  const q = useRef(_q).current;
  useEffect(() => {
    let ref = collection(db, c);
    if (q) {
      ref = query(ref, where(...q));
    }
    if (o) {
      ref = query(ref, orderBy(o, ob));
    }

    if (dataLimit) {
      ref = query(ref, limit(dataLimit));
    }
    const unsub = onSnapshot(ref, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setDocuments(results);
    });

    return () => unsub();
  }, [c, q, o, ob]);

  return { documents };
};
