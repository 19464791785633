import React, { useEffect, useState } from "react";
import styles from "../../components/Home/PopularBreeder.module.css";
import { Col, Row, Button, Container, Placeholder } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useCollection } from "../../hooks/useCollection";
import { useNavigate } from "react-router-dom";
import PopularBreederItem from "../../components/Home/PopularBreederItem";
import CustomBreadcrumb from "../../components/Universal/CustomBreadcrumb";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const AllBreeder = () => {
  const [breeder, setBreeder] = useState(null);
  const { width } = useWindowDimensions();
  let [breadcrumb, setBreadcrumb] = useState(null);
  const navigate = useNavigate();
  const { documents: BreederList } = useCollection(
    "users",
    ["group", "==", "breederpet"],
    "transferMNY",
    "desc"
  );

  useEffect(() => {
    if (BreederList) {
      setBreeder(BreederList);
    }
  }, [BreederList]);

  useEffect(() => {
    let breadcrumbItem = [];
    breadcrumbItem.push({ name: "サイト名", link: "/" });
    breadcrumbItem.push({ name: "All Breeder" });
    setBreadcrumb(breadcrumbItem);
  }, []);

  return (
    <div className={`${styles.grayArea} pb-2`}>
      {width > 576 && (
        <>
          <CustomBreadcrumb Breadcrumb={breadcrumb} />
        </>
      )}

      <Container>
        <div className="mt-sm-4 pb-sm-5">
          <h5 className={styles.title}>人気のブリーダー</h5>
          <Row>
            {!breeder && (
              <>
                <Placeholder animation="glow">
                  {Array.from({ length: 8 }, (_, i) => (
                    <Placeholder
                      key={i}
                      sm={{ span: 12 }}
                      className="rounded-2 p-4 my-3"
                      style={{ height: "400px" }}
                    />
                  ))}
                </Placeholder>
              </>
            )}

            {breeder && (
              <>
                {breeder.map((item, i) => {
                  return (
                    <Col key={i} sm={12} className="mt-4">
                      <PopularBreederItem
                        breeder={item}
                        fulColumnWidth={true}
                        withList={true}
                      />
                    </Col>
                  );
                })}
              </>
            )}
          </Row>
        </div>

        {width <= 576 && (
          <>
            <CustomBreadcrumb position={"bottom"} Breadcrumb={breadcrumb} />
          </>
        )}
      </Container>
    </div>
  );
};

export default AllBreeder;
