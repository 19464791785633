import React, { useEffect, useRef, useState } from "react";
import styles from "./FilterBar.module.css";
import {
  button,
  ButtonGroup,
  Col,
  Container,
  InputGroup,
  Row,
  Form
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faLocationDot,
  faPaw,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../../../src/hooks/useWindowDimensions";
import Select from "react-select";
import { dog_breed } from "../../utils/CatDogData";
import LocationSelectModal from "../template/Header/LocationSelectModal";
import { getCache, getName, locations, setCache } from "../../utils/Utils";
import { useAggregation } from "../../hooks/useAggregation";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import { useNavigate } from "react-router-dom";
import ArrayModal from "../../pages/Search/ArrayModal";

const FilterBar = () => {
  const componentRef = useRef(null);
  const { countWithQuery } = useAggregation();
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [locationView, setLocationView] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();
  const [showBreedModal, setShowBreedModal] = useState(false);

  const [breedView, setBreedView] = useState(false);
  const [selectStyle, setSelectStyle] = useState({
    minHeight: "50px", // Adjust the height as needed
    width: "100%", // Adjust the width as needed
    paddingLeft: "40px",
    border: "2px solid #2b2b2b",
    borderRadius: "0px"
  });

  const {
    documents: BreederList,
    isPending: isPendingList,
    getMultipleDocs
  } = useCollectionDocs();

  const [breederList, setBreederList] = useState([]);
  const handleModalClose = () => {
    setShowLocationModal(false);
  };
  const getSelectedLocationItems = (itemsWithParent, items) => {
    setLocationView(itemsWithParent.join(", "));
    setLocationValue(items);
  };
  const [activeButton, setActiveButton] = useState(1);
  const makeActive = (num) => {
    setActiveButton(num);
  };
  const [breed, setBreed] = useState([]);
  const [breeder, setBreeder] = useState(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    const handleHashChange = () => {
      const fragment = window.location.hash.substring(1);
      const queryParams = new URLSearchParams(fragment);
      const filterValue = queryParams.get("filter");
      if (filterValue) {
        componentRef.current.scrollIntoView();
        if (filterValue == "find-puppy") {
          makeActive(1);
        } else if (filterValue == "find-adult-dog") {
          makeActive(2);
        } else if (filterValue == "find-breeder") {
          makeActive(3);
        }
      }
    };
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [window.location.hash]);

  useEffect(() => {
    if (width > 576) {
      setSelectStyle({
        minHeight: "50px", // Adjust the height as needed
        width: "100%", // Adjust the width as needed
        paddingLeft: "40px",
        border: "1px solid #2b2b2b",
        borderRadius: "0px"
      });
    } else {
      setSelectStyle({
        minHeight: "50px", // Adjust the height as needed
        width: "100%", // Adjust the width as needed
        paddingLeft: "40px",
        border: "1px solid #2b2b2b",
        borderRadius: "10px"
      });
    }
  }, [width]);

  useEffect(() => {
    const fetchBreederData = async () => {
      const breederitems = [];
      await Promise.all(
        BreederList.map(async (item) => {
          const pets = await countWithQuery(`users/${item.id}/microchip/`, [
            ["sellPlatform", "==", true]
          ]);

          if (pets > 0 && item.id !== undefined) {
            breederitems.push({
              value: item.id,
              label: getName(item)
            });
          }
        })
      );

      if (breederitems.length > 0) {
        setCache("breeders", breederitems, "1h");
        setBreederList(breederitems);
      }
    };

    if (BreederList && BreederList.length > 0) {
      fetchBreederData();
    }
  }, [isPendingList]);

  useEffect(() => {
    const breederitems = getCache("breeders");
    if (breederitems) {
      setBreederList(breederitems);
    } else {
      loadData();
    }
  }, []);

  const loadData = () => {
    getMultipleDocs({
      _collection: `users`,
      _query: {
        1: `group,==,breederpet`
      }
    });
  };

  useEffect(() => {
    if (activeButton == 3) {
      if (locationValue.length > 0 || breed.length > 0 || breeder) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    } else {
      if (locationValue.length > 0 || breed != "") {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  }, [activeButton, locationValue, breed, breeder]);

  const handleForm = (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();
    queryParams.append("location", locationValue);
    queryParams.append("breed", breed);
    if (activeButton == 3) {
      if (breeder) {
        queryParams.append("breeder", breeder);
      }
      navigate(`/search/breeder/?${queryParams.toString()}`);
    } else {
      if (activeButton == 1) {
        queryParams.append("type", "puppy");
      } else {
        queryParams.append("type", "adult");
      }
      navigate(`/search?${queryParams.toString()}`);
    }
  };

  const updateBreed = (viewItem, items) => {
    viewItem = viewItem.map((i) => i.label);
    items = items.map((i) => i.value);
    setBreed(items);
    setBreedView(viewItem.join(", "));
  };

  return (
    <>
      <div className={styles.filterBar}>
        <div ref={componentRef} className={styles.refComponent}></div>
        <Container className="my-auto">
          <Row className="mt-2">
            <Col
              className="d-flex justify-content-center justify-content-sm-start"
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 10, offset: 1 }}
            >
              <ButtonGroup className={styles.buttonGroup}>
                <button
                  onClick={() => {
                    makeActive(1);
                  }}
                  className={`${
                    activeButton == 1 ? styles.active : ""
                  } rounded-0 ${styles.button}`}
                >
                  子犬を探す
                </button>
                <button
                  onClick={() => {
                    makeActive(2);
                  }}
                  className={`${
                    activeButton == 2 ? styles.active : ""
                  } rounded-0 ${styles.button}`}
                >
                  里親になる
                </button>
                <button
                  onClick={() => {
                    makeActive(3);
                  }}
                  className={`${
                    activeButton == 3 ? styles.active : ""
                  } rounded-0 ${styles.button}`}
                >
                  ブリーダーを探す
                </button>
              </ButtonGroup>
            </Col>
            <Col xs={{ span: 12, offset: 0 }} sm={{ span: 10, offset: 1 }}>
              <Form onSubmit={handleForm}>
                <input type="hidden" name="type" value={activeButton} />
                <Row>
                  {activeButton != 3 && (
                    <>
                      <Col xs={12} sm={5} className="pe-sm-0">
                        <div
                          className={`${styles.boxItem} ${styles.breed} ${styles.RightBorder} ${styles.LeftBorder}`}
                          onClick={(e) => {
                            e.preventDefault();
                            setShowBreedModal(true);
                          }}
                        >
                          <input type="hidden" name="breeder" value={breed} />
                          <p>{breedView != "" ? breedView : "犬種名で検索"}</p>
                          <p>{breedView}</p>
                        </div>
                      </Col>
                      <Col xs={12} sm={5} className="px-sm-0 mt-3 mt-sm-0">
                        <div
                          className={`${styles.boxItem} ${styles.location} `}
                          onClick={(e) => {
                            e.preventDefault();
                            setShowLocationModal(true);
                          }}
                        >
                          <input
                            type="hidden"
                            name="breeder"
                            value={locationValue}
                          />
                          <p>
                            {locationView != "" ? locationView : "地域名で検索"}
                          </p>
                        </div>
                      </Col>

                      <Col xs={12} sm={2} className="ps-sm-0 mt-3 mt-sm-0">
                        <button
                          className={`${styles.searchButton} px-4`}
                          disabled={disableButton}
                          type="submit"
                        >
                          {width > 576 && (
                            <>
                              <FontAwesomeIcon
                                className="me-2 ms-3"
                                icon={faSearch}
                              />{" "}
                              <span className="me-3">検索</span>
                            </>
                          )}

                          {width <= 576 && <>検索する</>}
                        </button>
                      </Col>
                    </>
                  )}
                  {activeButton == 3 && (
                    <>
                      <Col xs={12} sm={10}>
                        <Row>
                          <Col xs={12} sm={4} className="pe-sm-0">
                            <div
                              className={`${styles.boxItem} ${styles.breed} ${styles.RightBorder} ${styles.LeftBorder}`}
                              onClick={(e) => {
                                e.preventDefault();
                                setShowBreedModal(true);
                              }}
                            >
                              <input
                                type="hidden"
                                name="breeder"
                                value={breed}
                              />
                              <p>
                                {breedView != "" ? breedView : "犬種名で検索"}
                              </p>
                            </div>
                          </Col>
                          <Col xs={12} sm={4} className="px-sm-0 mt-3 mt-sm-0">
                            <div
                              className={`${styles.boxItem} ${styles.location} `}
                              onClick={(e) => {
                                e.preventDefault();
                                setShowLocationModal(true);
                              }}
                            >
                              <input
                                type="hidden"
                                name="breeder"
                                value={locationValue}
                              />
                              <p>
                                {locationView != ""
                                  ? locationView
                                  : "地域名で検索"}
                              </p>
                            </div>
                          </Col>
                          <Col xs={12} sm={4} className="px-sm-0 mt-3 mt-sm-0">
                            <Select
                              isDisabled={
                                breederList.length == 0 ? true : false
                              }
                              value={breederList.find(
                                (option) => option.value === breeder
                              )}
                              options={breederList}
                              placeholder={"ブリーダー名で検索"}
                              name="bredeer"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  ...selectStyle
                                })
                              }}
                              onChange={(selectedOption) => {
                                setBreeder(
                                  selectedOption ? selectedOption.value : null
                                );
                              }}
                              className={styles.select3}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} sm={2} className="ps-sm-0">
                        <button
                          className={`${styles.searchButton} px-4`}
                          disabled={disableButton}
                          type="submit"
                        >
                          {width > 576 && (
                            <>
                              <FontAwesomeIcon
                                className="me-2 ms-3"
                                icon={faSearch}
                              />{" "}
                              <span className="me-3">検索</span>
                            </>
                          )}

                          {width <= 576 && <>検索する</>}
                        </button>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <LocationSelectModal
        showModal={showLocationModal}
        handleModalClose={handleModalClose}
        getSelectedLocationItems={getSelectedLocationItems}
        preselected={locationValue}
      />

      <ArrayModal
        showArrayModal={showBreedModal}
        handleModalClose={() => {
          setShowBreedModal(false);
        }}
        updateFunction={updateBreed}
        array_item={dog_breed}
        preselected={breed}
      />
    </>
  );
};

export default FilterBar;
