import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Slider = () => {
  const { width } = useWindowDimensions();
  const [image, setImage] = useState("/assets/img/slider/1.png");
  useEffect(() => {
    if (width > 576) {
      setImage("/assets/img/slider/1.png");
    } else {
      setImage("/assets/img/slider/1-sm.png");
    }
  }, [width]);
  return (
    <>
      <div>
        <img className={`w-100 object-fit-cover`} src={image} />
      </div>
    </>
  );
};

export default Slider;
