import React, { useEffect, useState } from "react";
import { Collapse, Container, Form, Col, Row } from "react-bootstrap";
import styles from "./SearchPage.module.css";
import PetsIcon from "@mui/icons-material/Pets";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SideBar from "../../components/Home/SideBar/SideBar";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AnimalList from "../../components/Home/AnimalList";
import PopularDogBreedType from "../../components/Home/PopularDogBreedType";
import PopularBreeder from "../../components/Home/PopularBreeder";
import CustomBreadcrumb from "../../components/Universal/CustomBreadcrumb";
import { AnimalItemListSearchGrid } from "../../components/Universal/AnimalItemListSearchGrid";
import ArrayModal from "./ArrayModal";
import { cat_color, dog_breed } from "../../utils/CatDogData";
import LocationSelectModal from "../../components/template/Header/LocationSelectModal";
import DatePicker from "react-date-picker";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import "react-time-picker/dist/TimePicker.css";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { useCollectionDocsWithIn } from "../../hooks/useCollectionDocsWithIn";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useAddDocs } from "../../hooks/useAddDocs";
import { useCollection } from "../../hooks/useCollection";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import SearchHistoryItem from "../../components/Universal/SearchHistoryItem";
import { useToast } from "../../hooks/useToast";
import SearchHistoryModal from "./SearchHistoryModal";

const SearchPage = ({ breadcrumb }) => {
  const [searchHistory, setSearchHistory] = useState(null);
  const [allSearchHistory, setAllSearchHistory] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const { width } = useWindowDimensions();
  const { user } = useAuthContext();
  const { addADoc, isPending: isPending2, error: error2 } = useAddDocs();
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);
  const [searchModalshow, setSearchModalshow] = useState(false);

  const [showBreedModal, setShowBreedModal] = useState(false);
  const [showColorModal, setShowColorModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showHistoryBar, setShowHistoryBar] = useState(true);
  const urlLocation = useLocation();

  const toast = useToast();

  const {
    documents,
    isPending: isPendingList,
    getMultipleDocs
  } = useCollectionDocsWithIn();

  const {
    documents: SearchHistoryData,
    isPending: SearchHistoryPending,
    getMultipleDocs: getSearchHistoryMultipleDocs
  } = useCollectionDocs();

  const handleBreedModalClose = () => {
    setShowBreedModal(false);
  };

  const handleBreederModalShow = () => {
    setShowBreedModal(true);
  };

  const handleColorModalClose = () => {
    setShowColorModal(false);
  };

  const handleColorModalShow = () => {
    setShowColorModal(true);
  };

  const handleLocationModalClose = () => {
    setShowLocationModal(false);
  };

  const handleLocationModalShow = (e) => {
    e.preventDefault();
    setShowLocationModal(true);
  };

  //Form variable
  const [gender, setGender] = useState(null);
  const [breed, setBreed] = useState([]);
  const [breedView, setBreedView] = useState(null);
  const [breedHistoryView, setBreedHistoryView] = useState(null);
  const [color, setColor] = useState([]);
  const [colorView, setColorView] = useState(null);
  const [colorHistoryView, setColorHistoryView] = useState(null);
  const [location, setLocation] = useState([]);
  const [locationView, setLocationView] = useState(null);
  const [locationHistoryView, setLocationHistoryView] = useState(null);
  const [month, setMonth] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [birthdate, setBirthDate] = useState(null);
  const [visitDate, setVisitDate] = useState(null);
  const [keyword, setKeyword] = useState(null);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
  const [searchData, setSearchData] = useState(null);
  const [dataloadStart, setdataLoadStart] = useState(false);
  const [animalBirthFilter, setAnimalBirthFilter] = useState(null);
  const priceArray = [
    20000, 40000, 60000, 80000, 100000, 120000, 140000, 160000, 180000, 200000,
    220000, 240000, 260000, 280000, 300000
  ];

  const setFieldsDataFromHistory = (
    data,
    breedViewData,
    locationViewData,
    colorViewData
  ) => {
    // console.log(breedViewData);

    setSearchModalshow(false);
    if (data.breed) {
      setBreed(data.breed);
    } else {
      setBreed([]);
    }

    if (data.location) {
      setLocation(data.location);
    } else {
      setLocation([]);
    }

    if (data.gender) {
      setGender(data.gender);
    } else {
      setGender(null);
    }

    if (data.color) {
      setColor(data.color);
    } else {
      setColor([]);
    }

    if (data.month) {
      setMonth(data.month);
    } else {
      setMonth(null);
    }

    if (data.maxPrice) {
      setMaxPrice(data.maxPrice);
    } else {
      setMaxPrice(null);
    }

    if (data.minPrice) {
      setMinPrice(data.minPrice);
    } else {
      setMinPrice(null);
    }

    if (data.birthdate) {
      setBirthDate(data.birthdate);
    } else {
      setBirthDate(null);
    }

    if (data.keyword) {
      setKeyword(data.keyword);
    } else {
      setKeyword(null);
    }

    if (breedViewData) {
      setBreedView(breedViewData);
    } else {
      setBreedView(null);
    }
    if (colorViewData) {
      setColorView(colorViewData);
    } else {
      setColorView(null);
    }
    if (locationViewData) {
      setLocationView(locationViewData);
    } else {
      setLocationView(null);
    }
  };

  const [searchDoneAtlestOnce, setSearchDone] = useState(false);

  const handleGender = (item) => {
    if (gender === item) {
      setGender(null);
    } else {
      setGender(item);
    }
  };

  const updateBreed = (viewItem, items) => {
    viewItem = viewItem.map((i) => i.label);
    items = items.map((i) => i.value);
    setBreed(items);
    setBreedView(viewItem.join(", "));
  };

  const updateColor = (viewItem, items) => {
    setColor(items);
    viewItem = viewItem.map((i) => i.label);
    items = items.map((i) => i.value);
    setColor(items);
    setColorView(viewItem.join(", "));
  };

  const updateLocation = (viewItem, items) => {
    setLocation(items);
    setLocationView(viewItem.join(", "));
  };
  //End of Form

  const toggleCollapse = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (documents) {
      setSearchData(documents);
    }
  }, [documents]);

  //Excute if there is search params in url
  useEffect(() => {
    const searchParams = new URLSearchParams(urlLocation.search);
    if (searchParams.size == 0) {
      return;
    }

    let locationParam = searchParams.get("location");
    if (locationParam) {
      locationParam = locationParam
        .split(",")
        .filter((item) => item.trim() !== "");
    }

    let breedParam = searchParams.get("breed");
    if (breedParam) {
      breedParam = breedParam.split(",").filter((item) => item.trim() !== "");
    }
    const type = searchParams.get("type");

    let QueryArray = [];
    QueryArray.push(`sellPlatform,==,true`);

    if (locationParam && locationParam.length > 0) {
      setLocationView(locationParam.join(", "));
      setLocation(locationParam);
      QueryArray.push(`sellPlatform_prefecture,in,${locationParam}`);
    }
    if (breedParam && breedParam.length > 0) {
      setBreed(breedParam);
      const breedItem = dog_breed
        .filter((item) => breedParam.includes(item.value))
        .map((matchingItem) => matchingItem.label);
      if (breedItem) {
        if (breedItem.length == dog_breed.length) {
          setBreedView("すべて");
        } else {
          setBreedView(breedItem.join(","));
        }
      }
      QueryArray.push(`animal_breed,in,${breedParam}`);
    }

    if (type && type == "puppy") {
      QueryArray.push(`animal_birthDay,>=,${getOneYearAgoDate}`);
    }

    if (type && type == "adult") {
      QueryArray.push(`animal_birthDay,<=,${getOneYearAgoDate}`);
    }

    loadData({ ...QueryArray });
    setDisabledSubmitButton(false);
  }, [urlLocation.search]);

  const loadData = (query = false) => {
    // console.log(query);
    setdataLoadStart(true);
    getMultipleDocs({
      _collection: `microchip`,
      _query: query
    });
  };

  const loadHistory = () => {
    getSearchHistoryMultipleDocs({
      _collection: `users/${user.uid}/searchHistory`,
      _query: false,
      orderByField: "createdAt"
    });
  };

  useEffect(() => {
    if (user) {
      loadHistory();
    }
  }, [user]);

  useEffect(() => {
    if (SearchHistoryData && SearchHistoryData.length > 0) {
      const lastSearch = SearchHistoryData[0];
      setSearchHistory(lastSearch);
      setAllSearchHistory(SearchHistoryData);
      // console.log(lastSearch);
    }
  }, [SearchHistoryData]);

  const saveSearchData = () => {
    setSaveButtonDisable(true);
    const addSearchData = {
      breed: breed,
      location: location,
      gender: gender,
      color: color,
      month: month,
      minPrice: minPrice,
      maxPrice: maxPrice,
      birthdate: birthdate,
      keyword: keyword
    };

    addADoc(`users/${user.uid}/searchHistory`, addSearchData).then((res) => {
      loadHistory();
      setSaveButtonDisable(false);
      toast("success", "保存された検索パラメータ!");
    });
  };

  const clearAll = (e) => {
    e.preventDefault();
    setdataLoadStart(false);
    setBreed([]);
    setBreedView(null);
    setColor([]);
    setColorView(null);
    setLocation([]);
    setLocationView(null);
    setMinPrice(null);
    setMaxPrice(null);
    setMonth(null);
    setGender(null);
    setBirthDate(null);
    setKeyword(null);
    setDisabledSubmitButton(true);
    setSearchData(null);
  };

  useEffect(() => {
    if (
      breed.length > 0 ||
      location.length > 0 ||
      color.length > 0 ||
      gender ||
      maxPrice ||
      minPrice ||
      birthdate ||
      month ||
      keyword
    ) {
      setDisabledSubmitButton(false);
    } else {
      setDisabledSubmitButton(true);
    }
  }, [
    breed,
    color,
    gender,
    location,
    minPrice,
    maxPrice,
    birthdate,
    keyword,
    month
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let QueryArray = [];
    QueryArray.push(`sellPlatform,==,true`);
    if (breed.length > 0) {
      QueryArray.push(`animal_breed,in,${breed}`);
    }
    if (location.length > 0) {
      QueryArray.push(`sellPlatform_prefecture,in,${location}`);
    }
    if (color.length > 0) {
      QueryArray.push(`animal_color,in,${color}`);
    }
    if (gender) {
      QueryArray.push(`animal_gender,==,${gender}`);
    }

    if (minPrice) {
      QueryArray.push(`sellPlatformPrice,>=,${minPrice}`);
    }
    if (maxPrice) {
      QueryArray.push(`sellPlatformPrice,<=,${maxPrice}`);
    }

    if (month) {
      const { startDate, endDate } = getStartAndEndDate(month);
      setAnimalBirthFilter({ endDate, startDate });
    } else {
      setAnimalBirthFilter(null);
    }

    loadData({ ...QueryArray });
  };

  const getOneYearAgoDate = () => {
    const currentDate = new Date();
    // Subtracting one year from the current date
    currentDate.setFullYear(currentDate.getFullYear() - 1);
    // Formatting the date as "YYYY-MM-DD"
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const getStartAndEndDate = (month) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - month);
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];
    return { startDate: formattedStartDate, endDate: formattedEndDate };
  };

  return (
    <>
      <div className={styles.BG_GRAY}>
        <Container>
          <div className="mb-2 mb-sm-4">
            <h4 className={styles.heading}>里親になる</h4>
          </div>

          {showHistoryBar && searchHistory && user && (
            <div className={`${styles.history} position-relative`}>
              <div>
                <p>前回の検索条件</p>
              </div>
              <div className={styles.list}>
                <div
                  className={styles.searchHistoryItem}
                  onClick={(e) => {
                    setFieldsDataFromHistory(
                      searchHistory,
                      breedHistoryView,
                      locationHistoryView,
                      colorHistoryView
                    );
                  }}>
                  <SearchHistoryItem
                    setBreedView={setBreedHistoryView}
                    setLocationView={setLocationHistoryView}
                    setColoriew={setColorHistoryView}
                    searchHistory={searchHistory}
                  />
                </div>
              </div>
              <div className={`${width <= 576 ? styles.mMID : ""}`}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setSearchModalshow(true);
                  }}>
                  全ての検索履歴
                </a>
              </div>

              <div
                className={styles.cross}
                onClick={(e) => {
                  e.preventDefault();
                  setShowHistoryBar(false);
                }}>
                <CloseIcon className={styles.crossIcon} />
              </div>
            </div>
          )}

          <div className={styles.searchBOX}>
            <Form onSubmit={handleSubmit}>
              <div className={styles.mainFilterOptions}>
                <div className={styles.type1} onClick={handleBreederModalShow}>
                  <p className={styles.label}>犬種</p>
                  <PetsIcon className={styles.icon} />
                  <p className={styles.secondaryLabel}>{breedView}</p>
                  <div className={styles.interectiveIcon}>
                    <AddCircleIcon className={styles.normalIcon} />
                    <AddCircleOutlineTwoToneIcon
                      className={styles.hoveredIcon}
                    />
                  </div>
                </div>
                <div className={styles.type1} onClick={handleLocationModalShow}>
                  <p className={styles.label}>地域</p>
                  <LocationOnOutlinedIcon className={styles.icon} />
                  <p className={styles.secondaryLabel}>{locationView}</p>
                  <div className={styles.interectiveIcon}>
                    <AddCircleIcon className={styles.normalIcon} />
                    <AddCircleOutlineTwoToneIcon
                      className={styles.hoveredIcon}
                    />
                  </div>
                </div>
                <div className={styles.type2}>
                  <p className={styles.label}>性別</p>
                  <div className={styles.gender}>
                    <div
                      onClick={() => {
                        handleGender("オス"); //Male 1
                      }}
                      className={`${gender == "オス" ? styles.active : ""}`}>
                      <p>男の子</p>
                      <MaleIcon />
                    </div>
                    <div
                      onClick={() => {
                        handleGender("メス"); //Female 2
                      }}
                      className={`${gender == "メス" ? styles.active : ""}`}>
                      <p>女の子</p>
                      <FemaleIcon />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.secondaryFilterOption}>
                <div>
                  <a
                    onClick={toggleCollapse}
                    href="#"
                    className={styles.collapseLink}>
                    <ArrowRightIcon
                      className={`${styles.arrowIcon} ${
                        isOpen
                          ? styles.arrowIconRotate90
                          : styles.arrowIconRotate0
                      }`}
                    />
                    <p>こだわりの条件を追加</p>
                  </a>
                </div>
                <Collapse in={isOpen}>
                  <div className={styles.secondarySearchItems}>
                    <div
                      className={styles.item1}
                      onClick={handleColorModalShow}>
                      <p className={styles.label}>毛色</p>
                      <p className={styles.secondaryLabel}>{colorView}</p>
                      <div className={styles.interectiveIcon}>
                        <AddCircleIcon className={styles.normalIcon} />
                        <AddCircleOutlineTwoToneIcon
                          className={styles.hoveredIcon}
                        />
                      </div>
                    </div>
                    <div className={styles.item2}>
                      <p className={styles.label}>生後</p>
                      <Form.Select
                        onChange={(e) => {
                          const selectedValue =
                            e.target.value === "none"
                              ? null
                              : parseInt(e.target.value);
                          setMonth(selectedValue);
                        }}
                        value={month !== null ? month.toString() : "none"}
                        className={styles.select}>
                        <option disabled value="none" hidden>
                          すべて
                        </option>
                        <option value={0}>0 ヶ月以内</option>
                        <option value={1}>1 ヶ月以内</option>
                        <option value={2}>2 ヶ月以内</option>
                        <option value={3}>3 ヶ月以内</option>
                        <option value={4}>4 ヶ月以内</option>
                        <option value={5}>5 ヶ月以内</option>
                        <option value={6}>6 ヶ月以内</option>
                        <option value={7}>7 ヶ月以内</option>
                        <option value={8}>8 ヶ月以内</option>
                        <option value={9}>9 ヶ月以内</option>
                        <option value={10}>10 ヶ月以内</option>
                        <option value={11}>11 ヶ月以内</option>
                      </Form.Select>
                    </div>

                    <div className={styles.item3}>
                      <p className={styles.label}>価格</p>
                      <Form.Select
                        key={minPrice} // Add key prop here
                        value={minPrice !== null ? minPrice.toString() : "none"}
                        className={styles.select}
                        onChange={(e) => {
                          const selectedValue =
                            e.target.value === "none"
                              ? null
                              : parseInt(e.target.value);
                          setMinPrice(selectedValue);
                        }}>
                        <option disabled value="none" hidden>
                          下限なし
                        </option>
                        {priceArray.map((item, i) => {
                          if (maxPrice) {
                            if (item < maxPrice) {
                              return (
                                <option key={i} value={item}>
                                  {item} 円
                                </option>
                              );
                            }
                          } else {
                            return (
                              <option key={i} value={item}>
                                {item} 円
                              </option>
                            );
                          }
                        })}
                      </Form.Select>
                      <p className={styles.label}>〜</p>
                      <Form.Select
                        value={maxPrice !== null ? maxPrice.toString() : "none"} // Set the value explicitly
                        defaultValue={"none"}
                        className={styles.select}
                        onChange={(e) => {
                          setMaxPrice(e.target.value);
                        }}>
                        <option disabled value="none" hidden>
                          上限なし
                        </option>
                        {priceArray.map((item, i) => {
                          if (minPrice) {
                            if (item > minPrice) {
                              return (
                                <option key={i} value={item}>
                                  {item} 円
                                </option>
                              );
                            }
                          } else {
                            return (
                              <option key={i} value={item}>
                                {item} 円
                              </option>
                            );
                          }
                        })}
                      </Form.Select>
                    </div>

                    <div className={`${styles.item4} me-0`}>
                      <p className={styles.label}>誕生日</p>
                      <DatePicker
                        className={`form-control border-0`}
                        clearIcon={null}
                        calendarIcon={false}
                        format="MM月 dd日"
                        locale="ja-JP"
                        dayPlaceholder="-"
                        monthPlaceholder="-"
                        onChange={(val) => {
                          setBirthDate(val);
                        }}
                        value={birthdate}
                        name="visit_date_second_choice"
                      />
                    </div>

                    <div className={styles.type2}>
                      <div className={styles.gender}>
                        <div
                          onClick={() => {
                            handleGender("オス"); //Male 1
                          }}
                          className={`${
                            gender == "オス" ? styles.active : ""
                          }`}>
                          <p>男の子</p>
                          <MaleIcon />
                        </div>
                        <div
                          onClick={() => {
                            handleGender("メス"); //Female 2
                          }}
                          className={`${
                            gender == "メス" ? styles.active : ""
                          }`}>
                          <p>女の子</p>
                          <FemaleIcon />
                        </div>
                      </div>
                    </div>

                    {/* <div className={styles.item5}>
                      <p className={styles.label}>状況</p>
                      <Form.Select
                        defaultValue={"none"}
                        className={styles.select}
                      >
                        <option disabled value="none" hidden>
                          すべて
                        </option>
                        <option>販売中</option>
                      </Form.Select>
                    </div> */}
                    {/* <div className={styles.item6}>
                      <p className={styles.label}>お迎え予定日</p>
                      <DatePicker
                        className={`form-control border-0 ${styles.mobileDatePicker}`}
                        clearIcon={null}
                        calendarIcon={false}
                        format="MM月  dd日"
                        minDate={new Date()}
                        locale="ja-JP"
                        dayPlaceholder="-"
                        monthPlaceholder="-"
                        onChange={(val) => {
                          setVisitDate(val);
                        }}
                        value={visitDate}
                        name="visit_date_second_choice"
                      />
                    </div> */}

                    <div className={styles.item7}>
                      <input
                        type="text"
                        name="query"
                        placeholder="フリーキーワード"
                        value={keyword === null ? "" : keyword}
                        onChange={(e) => {
                          setKeyword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Collapse>
              </div>
              <hr className="w-100" style={{ opacity: "1" }}></hr>

              <div className="w-100 d-flex flex-column flex-sm-row justify-content-center align-items-center">
                <button
                  className={`${styles.button} text-center mx-1`}
                  type="submit"
                  disabled={disabledSubmitButton}>
                  <span className="material-symbols-outlined">search</span>
                  この条件で検索する
                </button>

                {!isPendingList && searchDoneAtlestOnce && user && (
                  <button
                    className={`${styles.button} text-center mx-1`}
                    type="button"
                    disabled={saveButtonDisable}
                    onClick={saveSearchData}>
                    <span className="material-symbols-outlined">bookmark</span>
                    検索条件の保存
                  </button>
                )}
              </div>

              <p onClick={clearAll} className={styles.warningText}>
                全ての条件をクリア
              </p>
            </Form>
          </div>
        </Container>

        <Container className="mb-2 mb-sm-5">
          <Row>
            <Col
              xs={12}
              sm={{ span: 9 }}
              className={`${width <= 576 ? "px-0" : "mt-sm-1"}`}>
              <AnimalItemListSearchGrid
                container={false}
                items={searchData}
                pending={isPendingList}
                dataloadStart={dataloadStart}
                keyword={keyword}
                birthdate={birthdate}
                setSearchDone={setSearchDone}
                animalBirthFilter={animalBirthFilter}
              />
              {width > 576 && <PopularDogBreedType moreButton={false} />}
            </Col>

            {width <= 576 && <PopularDogBreedType moreButton={false} />}

            {width > 576 && (
              <Col
                sm={{ span: 3 }}
                className={`${width <= 576 ? "px-0" : "mt-5 pt-4"}`}>
                <SideBar />
              </Col>
            )}
          </Row>
        </Container>

        <CustomBreadcrumb position={"bottom"} Breadcrumb={breadcrumb} />
      </div>
      <ArrayModal
        showArrayModal={showBreedModal}
        handleModalClose={handleBreedModalClose}
        updateFunction={updateBreed}
        array_item={dog_breed}
        preselected={breed}
      />

      <LocationSelectModal
        showModal={showLocationModal}
        handleModalClose={handleLocationModalClose}
        getSelectedLocationItems={updateLocation}
        preselected={location}
      />

      <ArrayModal
        showArrayModal={showColorModal}
        handleModalClose={handleColorModalClose}
        updateFunction={updateColor}
        array_item={cat_color}
        preselected={color}
      />
      <SearchHistoryModal
        showModal={searchModalshow}
        handleModalClose={() => {
          setSearchModalshow(false);
        }}
        updateFunction={setFieldsDataFromHistory}
        array_item={allSearchHistory}
      />
    </>
  );
};

export default SearchPage;
