import React, { useEffect, useState } from "react";
import styles from "./MobileSideBar.module.css";
import { Link } from "react-router-dom";

const MobileSidebarSecondLevelItem = ({ name, items, clickData = null }) => {
  const [showSecondMenu, setShowSecondMenu] = useState(false);
  const [willCloseSecondMenu, setWillCloseSecondMenu] = useState(false);

  useEffect(() => {
    if (willCloseSecondMenu === true) {
      const timerId = setTimeout(() => {
        setShowSecondMenu(false);
      }, 500);
      return () => clearTimeout(timerId);
    }
  }, [willCloseSecondMenu]);

  return (
    <>
      <a
        to="#"
        onClick={(e) => {
          e.preventDefault();
          if (clickData) {
            clickData();
          } else {
            setWillCloseSecondMenu(false);
            setShowSecondMenu(true);
          }
        }}
        className={styles.LinkedItem}
      >
        {name}{" "}
        <span
          className="material-symbols-outlined"
          onClick={() => {
            setWillCloseSecondMenu(true);
          }}
        >
          navigate_next
        </span>
      </a>

      {showSecondMenu && (
        <div
          className={`${styles.secondMenu} ${
            willCloseSecondMenu ? styles.hideMobileMenu : styles.showMobileMenu
          }`}
        >
          <div className={styles.header}>
            <span
              className="material-symbols-outlined"
              onClick={() => {
                setWillCloseSecondMenu(true);
              }}
            >
              arrow_back
            </span>
            <p>{name}</p>
          </div>

          <div className={styles.secondDiv}>
            {items.map((item, index) => {
              if (item.type === "link") {
                return (
                  <a href={item.url} key={index} className={styles.LinkedItem}>
                    {item.name}
                  </a>
                );
              }

              if (item.type === "img-link") {
                return (
                  <Link to={item.url} key={index} className={styles.LinkedItem}>
                    <div className={styles.img}>
                      <img src={item.img} />
                    </div>
                    {item.name}
                  </Link>
                );
              }
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default MobileSidebarSecondLevelItem;
