import React, { useEffect, useState } from "react";

import lozad from "lozad";
import { Col, Placeholder, Ratio, Row } from "react-bootstrap";

import { cat_breed, dog_breed } from "../../utils/CatDogData";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import "react-quill/dist/quill.snow.css";
import moment from "moment";

const PetDetailsShort = ({ isPending, petDatils }) => {
  const { width } = useWindowDimensions();

  const [breed, setBreed] = useState(null);

  useEffect(() => {
    // console.log(petDatils);
    if (petDatils && petDatils.mcData) {
      if (petDatils.mcData.animal_group === "犬") {
        setBreed(dog_breed);
      } else if (petDatils.mcData.animal_group === "猫") {
        setBreed(cat_breed);
      }
    }
  }, [petDatils]);

  const observer = lozad();
  observer.observe();

  return (
    <>
      {isPending && (
        <Row className="placeholder-glow">
          <Col lg={3} xs={5}>
            <Ratio aspectRatio={100}>
              <Placeholder />
            </Ratio>
          </Col>

          <Col lg={9} xs={7} className="position-relative d-flex align-items-center">
            <div
              className={`${width < 576 && `fs-7 pe-3`} ${
                width >= 576 &&
                `position-absolute top-50 start-50 translate-middle`
              } w-100 px-lg-5`}
            >
              <Row className="border-1 border-dark border-bottom py-lg-3">
                <Col sm={{ span: 4, offset: 1 }}>
                  <Placeholder xs={3} className="rounded-1" />
                </Col>
                <Col sm={6}>
                  <Placeholder xs={7} className="rounded-1" />
                </Col>
              </Row>
              <Row className="border-1 border-dark border-bottom py-lg-3">
                <Col sm={{ span: 4, offset: 1 }}>
                  <Placeholder xs={5} className="rounded-1" />
                </Col>
                <Col sm={6}>
                  <Placeholder xs={8} className="rounded-1" />
                </Col>
              </Row>
              <Row className="border-1 border-dark border-bottom py-lg-3">
                <Col sm={{ span: 4, offset: 1 }}>
                  <Placeholder xs={4} className="rounded-1" />
                </Col>
                <Col sm={6}>
                  <Placeholder xs={4} className="rounded-1" />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}

      {!isPending && petDatils && (
        <Row>
          <Col lg={3} xs={5}>
            <Ratio aspectRatio={100}>
              <img
                src={petDatils.images[0]}
                className="lozad img-fluid object-fit-cover"
                alt="pet"
              />
            </Ratio>
          </Col>

          <Col lg={9} xs={7} className="position-relative d-flex align-items-center">
            <div
              className={`${width < 576 && `fs-7 pe-3`} ${
                width >= 576 &&
                `position-absolute top-50 start-50 translate-middle`
              } w-100 px-lg-5`}
            >
              <Row className={` border-1 border-dark border-bottom py-lg-3`}>
                <Col sm={{ span: 4, offset: 1 }}>
                  <b>{petDatils.mcData.animal_group}種</b>
                </Col>
                <Col sm={6}>
                  {breed &&
                    breed.map((item) => {
                      if (item.value === petDatils.mcData.animal_breed) {
                        return item.label;
                      }
                    })}

                  {petDatils.mcData.animal_breed === "999" && (
                    <>{petDatils.mcData.animal_breed_other}</>
                  )}
                </Col>
              </Row>
              <Row className="border-1 border-dark border-bottom py-lg-3">
                <Col sm={{ span: 4, offset: 1 }}>
                  <b>誕生日</b>
                </Col>
                <Col sm={6}>
                  {moment(petDatils.mcData.animal_birthDay).format("YYYY/MM/DD")}生まれ
                </Col>
              </Row>
              <Row className="border-1 border-dark border-bottom py-lg-3">
                <Col sm={{ span: 4, offset: 1 }}>
                  <b>価格</b>
                </Col>
                <Col sm={6}>
                  {petDatils.sellPlatformPrice
                    ? `${parseInt(
                        petDatils.sellPlatformPrice
                      ).toLocaleString()}円 (税込)`
                    : ""}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PetDetailsShort;
