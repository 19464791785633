import React, { useEffect, useRef, useState } from "react";

import _ from "lodash";
import lozad from "lozad";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";

import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronRightOutlined, Female, Male } from "@mui/icons-material";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";

import styles from "./PetInfo.module.css";
import { useGetDoc } from "../../hooks/useGetDocs";
import useFavourite from "../../hooks/useFavourite";
import FavPet from "../../components/Universal/FavPet";
import AnimalItem from "../../components/Home/AnimalItem";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import PopularBreederItem from "../../components/Home/PopularBreederItem";
import CustomBreadcrumb from "../../components/Universal/CustomBreadcrumb";
import {
  cat_breed,
  cat_color,
  dog_breed,
  dog_color
} from "../../utils/CatDogData";

import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import BreederSortDetails from "../../components/Home/BreederSortDetails";
import AddressFormatter from "../../components/AddressFormatter";
import { useCollection } from "../../hooks/useCollection";
import { petCardDataGenerator } from "../../utils/Utils";

const PetInfo = ({ petID, breadcrumb }) => {
  const navigate = useNavigate();
  const { firebaseGetDoc, getSingleDocWithQuery, data, isPending, error } =
    useGetDoc();
  const [petDatils, setPetDetails] = useState(false);
  const [breederDatils, setBreederDetails] = useState(false);
  const [breed, setBreed] = useState(null);
  const [color, setColor] = useState(null);
  const [mainImg, setMainImg] = useState("/assets/img/no-img.png");
  const [mcData, setMcData] = useState({});
  const [birthDate, setBirthDate] = useState(null);
  const [date, setDate] = useState({});
  const [allImages, setAllImages] = useState([]);
  const [renderedSmallImages, setRenderedSmallImages] = useState([]);
  const { width } = useWindowDimensions();
  const [currentOwnerId, setCurrentOwnerId] = useState("");
  const [fade, setFade] = useState(false);

  const [animalListHot, setAnimalListHot] = useState([]);
  const [animalListNew, setAnimalListNew] = useState([]);
  const [createDate, setCreateDate] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [mobileDescriptionExpand, setMobileDescriptionExpand] = useState(false);
  const animalItemContainerRef = useRef(null);
  const [
    showmobileDescriptionExpandButton,
    setShowMobileDescriptionExpandButton
  ] = useState(false);
  const descriptionContainerRef = useRef(null);
  const descriptionRef = useRef(null);

  //Mobile Fixed Position Button Fav.
  const {
    isReady,
    isFavourite,
    isPending: isPending2,
    addToFavourite,
    removeFromFavourite,
    totalFav
  } = useFavourite(petID);

  useEffect(() => {
    updateMainImg(mainImg);
  }, [allImages]);

  useEffect(() => {
    const getPetMCDetails = async () => {
      const mc = await getSingleDocWithQuery("microchip", [
        ["animalID", "==", petID],
        ["sellPlatform", "==", true]
      ]);
      if (!mc && mc.length == 0) {
        navigate("/404");
        return;
      }
      setCurrentOwnerId(mc[0].currentOwnerUID);
      const data = await getSingleDocWithQuery(
        `users/${mc[0].currentOwnerUID}/microchip/`,
        [
          ["animalID", "==", petID],
          ["sellPlatform", "==", true]
        ]
      );
      const breeder = await firebaseGetDoc("users", mc[0].currentOwnerUID);
      if (data && data.length > 0) {
        if (breeder) {
          // console.log(breeder);
          setBreederDetails({ ...breeder, id: mc[0].currentOwnerUID });
        }
        setPetDetails(data[0]);
        setMcData(data[0].mcData);
        setBirthDate(
          moment(data[0].mcData.animal_birthDay).format("YYYY年MM月DD日")
        );
        setDate(data[0].createdAt.toDate());
        setAllImages(data[0].images);
        setRenderedSmallImages(data[0].images);
        setCreateDate(
          moment(
            new Timestamp(
              data[0].sellPlatformAt.seconds,
              data[0].sellPlatformAt.nanoseconds
            ).toDate()
          ).format("YYYY年MM月DD日")
        );
        setUpdateDate(
          moment(
            new Timestamp(
              data[0].sellPlatformUpdateAt.seconds,
              data[0].sellPlatformUpdateAt.nanoseconds
            ).toDate()
          ).format("YYYY年MM月DD日")
        );

        if (data[0].images && data[0].images.length > 0) {
          setMainImg(data[0].images[0]);
        }
        if (data[0].mcData.animal_group === "犬") {
          setBreed(dog_breed);
          setColor(dog_color);
        } else if (data[0].mcData.animal_group === "猫") {
          setBreed(cat_breed);
          setColor(cat_color);
        }
      } else {
        navigate("/404");
        return;
      }
    };
    getPetMCDetails();
  }, [petID]);

  const updateMainImg = (img) => {
    if (allImages && allImages.length > 0) {
      //Willuncomment
      // setRenderedSmallImages(allImages.filter((i) => i != img));
    }

    setFade(false);
    setTimeout(() => {
      setMainImg(img);
      setFade(true);
    }, 200);
  };

  const observer = lozad();
  observer.observe();

  useEffect(() => {
    if (
      descriptionContainerRef.current.clientHeight <
      descriptionRef.current.clientHeight
    ) {
      setShowMobileDescriptionExpandButton(true);
    }
  }, [width]);

  const [animalListRecommend, setAnimalListRecommend] = useState([]);
  const { documents: recommendPets } = useCollection(
    "recommended",
    ["RECOMMEND", "==", true],
    "recommend_position",
    "asc"
  );

  useEffect(() => {
    const getPetDetails = async () => {
      if (recommendPets) {
        let pets = [];
        recommendPets.forEach(async (petitem) => {
          let pet = await getSingleDocWithQuery("microchip", [
            ["animalID", "==", petitem.animalID]
          ]);
          let petData = petCardDataGenerator(pet[0]);
          // petData.tag = "hot";
          pets.push(petData);
        });
        setAnimalListRecommend(pets);
      }
    };
    getPetDetails();
  }, [recommendPets]);

  return (
    <>
      {/* <div className="position-fixed top-50 start-50">
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div> */}

      <Container className="mt-2 mt-sm-5">
        <Row className="mt-0 pt-0 ">
          <Col xs={12} sm={6}>
            <div className={styles.dataDates}>
              <p>
                掲載日：{createDate}　　
                <br className="d-inline d-sm-none" />
                更新日：{updateDate}
              </p>{" "}
              {petDatils && petDatils.product_type === "里親" && (
                <>
                  <div className={`parentTag ms-2 my-0`}>
                    {petDatils.product_type}
                  </div>
                </>
              )}
            </div>
            <h4 className={styles.heading}>
              {mcData.animal_breed !== "999"
                ? _.find(breed, ["value", mcData.animal_breed])?.label
                : mcData.animal_breed_other}
              の詳細情報
            </h4>
          </Col>
          {width > 576 && (
            <Col sm={6} className="text-end">
              <FavPet id={petID} />
            </Col>
          )}
        </Row>
        <Row className="mt-2 mt-sm-0">
          <Col sm={5}>
            <div className="ratio ratio-1x1 my-2">
              <img
                src={mainImg}
                className={`lozad img-fluid object-fit-cover ${
                  styles.mainImg
                } ${fade ? styles.fadeIn : styles.fadeOut}`}
              />
            </div>
            {renderedSmallImages && (
              <div className={styles.smallIMGContainer}>
                {renderedSmallImages.map((img, index) => {
                  return (
                    <div className={styles.smallIMG} key={index}>
                      <div className="ratio ratio-1x1 my-0 my-sm-2">
                        <img
                          src={img}
                          onClick={() => {
                            updateMainImg(img);
                          }}
                          style={{ cursor: "pointer" }}
                          className="lozad img-fluid object-fit-cover"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </Col>

          <Col
            sm={7}
            className="d-flex justify-content-center flex-column mt-4 mt-sm-0">
            <div className="w-100">
              <table className={styles.table}>
                <tbody>
                  <tr>
                    <td>犬種</td>
                    <td>
                      {mcData.animal_breed !== "999"
                        ? _.find(breed, ["value", mcData.animal_breed])?.label
                        : mcData.animal_breed_other}
                    </td>
                  </tr>
                  <tr>
                    <td>見学地域</td>
                    <td>
                      {/* Span will bring this sign ">" */}
                      <div className={styles.address}>
                        <AddressFormatter
                          zip3={petDatils.sellPlatform_zip3}
                          zip4={petDatils.sellPlatform_zip4}
                        />
                      </div>
                    </td>
                  </tr>
                  {petDatils.sellPlatformPrice && (
                    <tr>
                      <td>価格</td>
                      <td>
                        {" "}
                        {parseInt(petDatils.sellPlatformPrice).toLocaleString(
                          "en-US"
                        )}
                        円（税込）
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>誕生日</td>
                    <td>{birthDate}生まれ</td>
                  </tr>
                  <tr>
                    <td>毛色</td>
                    <td>
                      {mcData.animal_color !== "99"
                        ? _.find(color, ["value", mcData.animal_color])?.label
                        : mcData.animal_color_other}
                    </td>
                  </tr>
                  <tr>
                    <td>性別</td>
                    <td>
                      {mcData.animal_gender === "オス" ? (
                        <>
                          男の子 <Male />
                        </>
                      ) : (
                        <>
                          女の子 <Female />
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>生体保証</td>
                    <td>{petDatils.guarantee}</td>
                  </tr>
                  <tr>
                    <td>アピールポイント</td>
                    <td>{petDatils.prPoint}</td>
                  </tr>
                  <tr>
                    <td>成犬時予想</td>
                    <td>
                      体重: {petDatils.weight_from}~{petDatils.weight_to}
                      kg　体高: {petDatils.height_from}~{petDatils.height_to}cm
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={styles.requestButton}>
              <Link
                to={`/inquiry/${petID}`}
                className={`${styles.inquiryBtn} d-inline-block text-decoration-none`}>
                見学希望 / お問い合わせ
              </Link>
            </div>
          </Col>
          {width <= 576 && (
            <Col xs={12} className="text-end">
              <FavPet fullWidth={true} id={petID} />
            </Col>
          )}
        </Row>
      </Container>

      <div className={styles.BG_GRAY}>
        <Container className="mb-1 mb-sm-5">
          <Row>
            <Col sm={12}>
              <h4 className={styles.heading}>ブリーダーからの紹介</h4>
            </Col>
            <Col sm={12}>
              <div
                ref={descriptionContainerRef}
                className={`w-100 bg-white rounded-3 p-4 mt-3 ${
                  width <= 576 && !mobileDescriptionExpand
                    ? styles.mobileDescriptionCollaps
                    : styles.mobileDescriptionUnCollaps
                }`}>
                <p ref={descriptionRef} className={styles.description}>
                  <div className="ql-snow">
                    <div
                      className=" ql-editor p-0"
                      dangerouslySetInnerHTML={{
                        __html: petDatils.sellPlatformDescription
                      }}></div>
                  </div>
                </p>

                {width <= 576 && (
                  <>
                    {showmobileDescriptionExpandButton && (
                      <>
                        <div className={styles.expandButton}>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              setMobileDescriptionExpand(true);
                              setShowMobileDescriptionExpandButton(false);
                            }}
                            href="#">
                            続きを読む
                          </a>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </Col>

            <div className={`${styles.requestButton} m-0 w-100`}>
              <Link
                to={`/inquiry/${petID}`}
                className={`${styles.inquiryBtn} d-inline-block text-decoration-none`}>
                見学希望 / お問い合わせ
              </Link>
            </div>
          </Row>

          {breederDatils && (
            <Row className="mt-4 mt-sm-0">
              <Col sm={12}>
                <h4 className={`mb-3 ${styles.heading}`}>ブリーダー情報</h4>

                <BreederSortDetails
                  isPending={isPending}
                  breeder={breederDatils}
                  showRegDate={true}
                  showChangeDate={true}
                  fulColumnWidth={true}
                  withList={false}
                />
              </Col>
            </Row>
          )}

          {/* <Row className="mt-5">
            <Col sm={6}>
              <h4 className={`mb-3 ${styles.heading}`}>
                似た検索条件のワンちゃん一覧
              </h4>
            </Col>
            {width > 576 && (
              <Col sm={6} className="d-flex align-items-center">
                <a href="#" className={styles.seeAllLink}>
                  すべて見る
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </a>
              </Col>
            )}

            <Col sm={12} className={`${width <= 576 ? "pe-0" : ""}`}>
              <div className={styles.animalItemRow}>
                {animalListNew.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      className={`${styles.animalItemCard} pe-0`}
                    >
                      <AnimalItem data={item} type="longcard" />
                    </Col>
                  );
                })}
              </div>
            </Col>
          </Row> */}

          <Row className="mt-5">
            <Col sm={6}>
              <h4 className={`mb-3 ${styles.heading}`}>
                おすすめのワンちゃん一覧
              </h4>
            </Col>
            {/* {width > 576 && (
              <Col sm={6} className="d-flex align-items-center">
                <a href="#" className={styles.seeAllLink}>
                  すべて見る
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </a>
              </Col>
            )} */}

            <Col sm={12} className={`${width <= 576 ? "pe-0" : ""}`}>
              <div className={styles.animalItemRow}>
                {animalListRecommend.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      ref={animalItemContainerRef}
                      className={`${styles.animalItemCard} pe-0 me-3`}>
                      <AnimalItem
                        animalItemContainerRef={animalItemContainerRef}
                        data={item}
                        type="longcard"
                      />
                    </Col>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>

        <CustomBreadcrumb position={"bottom"} Breadcrumb={breadcrumb} />

        {width <= 576 && (
          <div className={styles.bottomFixed}>
            <div className={`${styles.requestButton}`}>
              <Link
                to={`/inquiry/${petID}`}
                className={`${styles.inquiryBtn} d-inline-block text-decoration-none`}>
                見学希望 / お問い合わせ
              </Link>
            </div>

            <div className={styles.floatingFav}>
              <FavoriteOutlinedIcon style={{ color: "#9F9F9F" }} />
              <p className="m-0">24人</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PetInfo;
